import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import languageReducer from './Language/language.reducer';
import appearReducer from './Appear/appear.reducer';

export const rootReducer = combineReducers({
  languageData: languageReducer,
  appearData: appearReducer
});

const configStorage = {
  key: 'root',
  storage
};

export default persistReducer(configStorage, rootReducer);
