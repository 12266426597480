//COMPONENTS START

import reportWebVitals from './reportWebVitals';
import "./i18n";
import {Provider} from 'react-redux';
import { store, persistor } from './redux/createStore';
import { PersistGate } from 'redux-persist/integration/react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

//COMPONENTS END

//CSS RULES START

import './index.css';
import 'react-phone-input-2/lib/style.css';
import '../node_modules/semantic-ui-css/semantic.min.css';
import '../src/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/css/flickity.css';
import '../src/css/stack-interface.css';
import '../src/css/socicon.css';
import '../src/css/theme.css';
import '../src/sass/custom.scss';

//CSS RULES END

//MYCOMPONENTS START

import App from './App';

//MYCOMPONENTS END


ReactDOM.render(
  <React.Fragment>
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

