import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next';
class AccordionExampleFluid extends Component {

    state = { activeIndex: -1 };
    handleClick = (e, titleProps) => {
     

        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { t } = this.props;
     
        const { activeIndex } = this.state;
        return (
        
            <Accordion fluid styled>
                <div className="col-12 question">
                    <ul className="accordion accordion-1">
                        <li>

                            <Accordion.Title
                          
                                active={activeIndex === 0}
                                index={0}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >

                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q1")}</span></div> 
                            </Accordion.Title>

                            <Accordion.Content active={activeIndex === 0}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a1")}
                                </p>
                            </Accordion.Content>

                        </li>
                    </ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q2")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a2")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 2}
                                index={2}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"> <span className="h4 mb-0">{t("Accord_section.bodyText.q3")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a3")}
                                                                    Az egyórás körút során a Tihanyi-félsziget és a Balaton
                                                                    nyugati öblének szépségét csodálhatod meg.
          </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 3}
                                index={3}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q4")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 3}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a4")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 4}
                                index={4}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q5")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 4}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a5")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 5}
                                index={5}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"> <span className="h4 mb-0">{t("Accord_section.bodyText.q6")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 5}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a6")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 6}
                                index={6}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"> <span className="h4 mb-0">{t("Accord_section.bodyText.q7")}</span></div>

                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 6}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a7")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 7}
                                index={7}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin">  <span className="h4 mb-0">{t("Accord_section.bodyText.q8")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 7}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a8")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 8}
                                index={8}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q9")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 8}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a9")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                               active={activeIndex === 9}
                                index={9}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"> <span className="h4 mb-0">{t("Accord_section.bodyText.q10")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 9}>
                                <p className="accordion-text-margin"> 
                                    {t("Accord_section.bodyText.a10")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                    <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 10}
                                index={10}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q11")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 10}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a11")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                        <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 11}
                                index={11}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q12")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 11}>
                           
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a12")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                        <ul className="accordion accordion-1">
                        <li>
                            <Accordion.Title
                                active={activeIndex === 12}
                                index={12}
                                onClick={this.handleClick}
                                className="h4 mb-0"
                            >
                                <Icon name='dropdown' />
                                <div className="accordion-title-margin"><span className="h4 mb-0">{t("Accord_section.bodyText.q13")}</span></div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 12}>
                                <p className="accordion-text-margin">
                                    {t("Accord_section.bodyText.a13")}
                                </p>
                            </Accordion.Content>
                        </li></ul>
                </div>
            </Accordion>
            
        )
    }
}

export default withTranslation()(AccordionExampleFluid)