//COMPONENTS
import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';
//COMPONENTS END

//MYCOMPONENTS
import Aux from '../../src/components/Hook/Auxiliary/index'
import EmailSender from '../components/Form/EventPageSenderForm/emailSender'
//ENDMYCOMPONENTS

//IMGS
import kisfaludy_480_30 from '../img/480/kisfaludy-480-30.jpg';
import kisfaludy_512_30 from '../img/512/kisfaludy-512-30.jpg';
import kisfaludy_768_30 from '../img/768/kisfaludy-768-30.jpg';


import kisfaludy_480_29 from '../img/480/kisfaludy-480-29.jpg';
import kisfaludy_512_29 from '../img/512/kisfaludy-512-29.jpg';
import kisfaludy_768_29 from '../img/768/kisfaludy-768-29.jpg';


import kisfaludy_480_33 from '../img/480/kisfaludy-480-33.jpg';
import kisfaludy_512_33 from '../img/512/kisfaludy-512-33.jpg';
import kisfaludy_768_33 from '../img/768/kisfaludy-768-33.jpg';


import kisfaludy_480_32 from '../img/480/kisfaludy-480-32.jpg';
import kisfaludy_512_32 from '../img/512/kisfaludy-512-32.jpg';
import kisfaludy_768_32 from '../img/768/kisfaludy-768-32.jpg';
//IMGS END

class Rendezvenyek extends Component {

    constructor() {
        super();
        this.SelectCategory = this.SelectCategory.bind(this);
        this.AdjustEventSection = this.AdjustEventSection.bind(this);
        this.state = { isLoading: false }
    }



    // Adjusts elements in Event Section to properly fit
    // current window width
    AdjustEventSection() {

        this.forceUpdate(function () {
            var data = {
                other: {
                    alreadyLoaded: false
                },
                window: {
                    currentWidth: null,
                    currentHeight: null
                },
                mainSection: {
                    itemClass: $(".events--section")
                },
                eventsWrapper: {
                    itemClass: $(".events__events-wrapper"),
                    width: null,
                    height: null
                },
                item: {
                    itemClass: $(".event-item"),
                    totalItemsWidth: 0,
                    totalItemNum: 0,
                },
                itemTextContainer: {
                    itemClass: $(".event__text-container")
                },
                title: {
                    itemClass: $(".event__title")
                },
                hiddenGroup: {
                    itemClass: $(".event__hidden-textgroup")
                },
                hr: {
                    itemClass: $(".event__hidden-textgroup hr")
                },
                bodyText: {
                    itemClass: $(".event__bodytext")
                }
            };
            if (this.state.isLoading) {
                setTimeout(function () {
                    InitSetup();
                }, 100)
            }

            $(window).on("load", function () {
                setTimeout(function () {
                    InitSetup();
                }, 1000)
                // console.log("refreshed");
            });


            $(window).on("resize", function () {

                if ($(window).width() !== data.window.currentWidth) {
                    FetchSizes();
                }
            });


            function InitSetup() {
                FetchSizes();
            }

            function FetchSizes() {

                data.item.totalItemNum = 0;

                data.window.currentWidth = $(window).width();
                data.window.currentHeight = $(window).height();
                data.eventsWrapper.width = $(data.eventsWrapper.itemClass).width();
                //  console.log(data.eventsWrapper.width);

                $.each($(data.item.itemClass), function (index, value) {
                    data.item.totalItemsWidth += $(value).width();
                    data.item.totalItemNum++;
                });

                AdjustItemSizes();
            }

            function AdjustItemSizes() {
                if (data.window.currentWidth > 1024) {
                    $(data.item.itemClass).css("max-width", data.eventsWrapper.width / data.item.totalItemNum);
                } else if (data.window.currentWidth > 768 && data.window.currentWidth <= 1024) {
                    $(data.item.itemClass).css("max-width", data.eventsWrapper.width / 2);
                } else if (data.window.currentWidth <= 768) {
                    $(data.item.itemClass).css("max-width", "");
                }

            }
        });




    }

    SelectCategory() {
        var classForShow = "selected";

        $("li[data-event]").click(function () {

            var btnData = $(this).data("event");

            $("li[data-event]").removeClass("active");
            $(this).addClass("active");
            $("li[data-event]").removeClass(classForShow);
            $(this).addClass(classForShow);

            $("[data-event]").removeClass(classForShow);
            $("[data-event='" + btnData + "']").first().addClass(classForShow);
        });
    }


    componentDidMount() {
        this.SelectCategory();
        this.AdjustEventSection()
        //this.toggleNav()
        this.setState({ isLoading: true })
    }
    render() {
        const { t } = this.props;
        return (
            <Aux>

                <section className="section hero-section hero-section--rendezvenyek" id="rendezvenyek-hero">

                    <span className="filter--black filter--black--gradient"></span>
                    <div className="hero-section__content">
                        <div className="container container-xl">
                            <div className="col-12 col-md-8 col-lg-6">
                                <div className="hero-section__text">
                                    <span className="hero-section__title"><strong>{t("EventsPage.Hero_section.boxTitle")}</strong></span>
                                    <hr className="short" />
                                    <div className="hero-section__bodytext">
                                        <p>{t("EventsPage.Hero_section.bodyText.p1")}</p>
                                        <p>{t("EventsPage.Hero_section.bodyText.p2")}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                <section className="section section--fullwidth events--section">

                    <div className="container container--xxl">
                        <div className="row justify-content-center align-items-center">

                            <div className="section__titlebar titlebar--center">
                                <h2>{t("EventsPage.Events_section.sectionTitle")}</h2>
                            </div>

                            <div className="col-12">
                                <div className="events__events-wrapper row justify-content-center no-gutters">

                                    {/* Esküvő */}

                                    <div className="event--eskuvo event-item">
                                        <Link
                                            to="/eskuvo-rendezvenyek"
                                        >
                                            <div className="event-item__content">
                                                <div className="event__image">
                                                    <img alt='event' src={kisfaludy_480_30}
                                                        srcSet={`${kisfaludy_512_30} 512w, ${kisfaludy_768_30} 768w`} />
                                                </div>
                                                <div className="event__text-container">
                                                    <h3 className="event__title textsize-big">{t("EventsPage.Events_section.bodyText.event_wedding.boxTitle")}</h3>
                                                    <div className="event__hidden-textgroup">
                                                        <hr />
                                                        <p className="event__bodytext textsize-medium">
                                                            {t("EventsPage.Events_section.bodyText.event_wedding.introText")}
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </Link>

                                    </div>


                                    {/* Céges Rendezvények */}
                                    <div className="event--ceges event-item">
                                        <Link to="/ceges-rendezvenyek">
                                            <div className="event-item__content">
                                                <div className="event__image">
                                                    <img alt='corp-event' src={kisfaludy_480_29}
                                                        srcSet={`${kisfaludy_512_29} 512w, ${kisfaludy_768_29} 768w`} />
                                                </div>
                                                <div className="event__text-container">
                                                    <h3 className="event__title textsize-big">{t("EventsPage.Events_section.bodyText.event_company.boxTitle")}</h3>
                                                    <div className="event__hidden-textgroup">
                                                        <hr />
                                                        <p className="event__bodytext textsize-medium">
                                                            {t("EventsPage.Events_section.bodyText.event_company.introText")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* Családi rendezvény */}
                                    <div className="event--csaladi event-item">
                                        <Link to='/csaladi-rendezvenyek'>
                                            <div className="event-item__content">
                                                <div className="event__image">
                                                    <img alt='corp-event' src={kisfaludy_480_33}
                                                        srcSet={`${kisfaludy_512_33} 512w, ${kisfaludy_768_33} 768w`} />
                                                </div>
                                                <div className="event__text-container">
                                                    <h3 className="event__title textsize-big">{t("EventsPage.Events_section.bodyText.event_birthday.boxTitle")}</h3>
                                                    <div className="event__hidden-textgroup">
                                                        <hr />
                                                        <p className="event__bodytext textsize-medium">
                                                            {t("EventsPage.Events_section.bodyText.event_birthday.introText")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* Fotó és forgatás */}
                                    <div className="event--eskuvo event-item">
                                        <Link to="/foto-es-forgatas">
                                            <div className="event-item__content">
                                                <div className="event__image">
                                                    <img alt='corp-event2' src={kisfaludy_480_32}
                                                        srcSet={`${kisfaludy_512_32} 512w, ${kisfaludy_768_32} 768w`} />
                                                </div>
                                                <div className="event__text-container">
                                                    <h3 className="event__title textsize-big">{t("EventsPage.Events_section.bodyText.event_photo.boxTitle")}</h3>
                                                    <div className="event__hidden-textgroup">
                                                        <hr />
                                                        <p className="event__bodytext textsize-medium">
                                                            {t("EventsPage.Events_section.bodyText.event_photo.introText")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="section contact-us--section bg--secondary">

                    <div className="container container-xl">
                        <div className="row justify-content-between ">


                            <div className="col-12 col-lg-6">
                                <div className="text-container section__text-block">

                                    <h3 className="text-block__title">                                                           
                                    {t("EventsPage.Events_section.bodyText.form_section.boxTitle")}
                                    </h3>
                                    <hr className="short " />
                                    <p>                                                            
                                        {t("EventsPage.Events_section.bodyText.form_section.introText")}
                                    </p>
                                    <ul>
                                        <li><b>{t("Map_section.subtitle")}  </b>{t("Map_section.subtitleText")}</li>

                                    </ul>


                                </div>
                            </div>

                            <div className="col-12 col-lg-6">

                                <div className="form-container boxed boxed--compact boxed--border border--light boxed--shadow">
                                <Suspense fallback={<h1>Loading</h1>}>
                                        <EmailSender />
                                  </Suspense>
                                </div>

                            </div>

                        </div>


                    </div>


                </section>

            </Aux>
        )
    }
}

export default withTranslation()(Rendezvenyek);