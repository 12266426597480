import React, { useEffect, useState } from "react";

const Expire = props => {
  const [visible, setVisible] = useState(true);


 
  useEffect(() => {
   
    setTimeout(() => {
      if(!props.clickEvent){
      setVisible(false);
      }
    }, props.delay);
  }, [props.delay,props.clickEvent]);



  return visible ? <div>{props.children}</div> : <div />;
};

export default Expire;