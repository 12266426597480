import React from 'react'
import HTMLFlipBook from "react-pageflip";

import page1 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak2-min.png';
import page2 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak3-min.png';
import page3 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak4-min.png';
import page4 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak5-min.png';
import page5 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak6-min.png';
import page6 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak7-min.png';
import page7 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak8-min.png';
import page8 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak9-min.png';
import page9 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak10-min.png';
import page10 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak11-min.png';
import page11 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak12-min.png';
import page12 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak13-min.png';
import page13 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak14-min.png';
import page14 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak15-min.png';
import page15 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak16-min.png';
import page16 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak17-min.png';
import page17 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak18-min.png';
import page18 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak19-min.png';
import page19 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak20-min.png';
import page20 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak21-min.png';
import page21 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak22-min.png';
import page22 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak23-min.png';
import page23 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak24-min.png';
import page24 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak25-min.png';
import page25 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak26-min.png';
import page26 from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak27-min.png';

import pageCoverFront from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak-min.png';
import pageCoverBack from '../../img/menu/PNG/Kisfaludy_Itallap_Oldalak28-min.png';

const Page = React.forwardRef((props, ref) => {


  return (
    <div className="demoPage" ref={ref}>
      <p>{props.children}</p>
    </div>
  );
});

function MyBook(props) {


  return (
    <HTMLFlipBook drawShadow={false} width={320}
      height={450}
      size="stretch"
      minWidth={315}
      maxWidth={1000}
      minHeight={400}
      maxHeight={1533} showCover={true} mobileScrollSupport={true}>
      <Page number="1">  
            <img className="first-page" src={pageCoverFront} alt="page0" />
      </Page>
      <Page number="2"><img src={page1} alt="page1" /></Page>
      <Page number="3"><img src={page2} alt="page2" /></Page>
      <Page number="4"><img src={page3} alt="page3" /></Page>
      <Page number="5"><img src={page4} alt="page4" /></Page>
      <Page number="6"><img src={page5} alt="page5" /></Page>
      <Page number="7"><img src={page6} alt="page6" /></Page>
      <Page number="8"><img src={page7} alt="page7" /></Page>
      <Page number="9"><img src={page8} alt="page8" /></Page>
      <Page number="10"><img src={page9} alt="page9" /></Page>
      <Page number="11"><img src={page10} alt="page10" /></Page>
      <Page number="12"><img src={page11} alt="page11" /></Page>
      <Page number="13"><img src={page12} alt="page12" /></Page>
      <Page number="14"><img src={page13} alt="page13" /></Page>
      <Page number="15"><img src={page14} alt="page14" /></Page>
      <Page number="16"><img src={page15} alt="page15" /></Page>
      <Page number="17"><img src={page16} alt="page16" /></Page>
      <Page number="18"><img src={page17} alt="page17" /></Page>
      <Page number="19"><img src={page18} alt="page18" /></Page>
      <Page number="20"><img src={page19} alt="page19" /></Page>
      <Page number="21"><img src={page20} alt="page20" /></Page>
      <Page number="22"><img src={page21} alt="page21" /></Page>
      <Page number="23"><img src={page22} alt="page22" /></Page>
      <Page number="24"><img src={page23} alt="page23" /></Page>
      <Page number="25"><img src={page24} alt="page24" /></Page>
      <Page number="26"><img src={page25} alt="page25" /></Page>
      <Page number="27"><img src={page26} alt="page26" /></Page>
      <Page number="28"><img src={pageCoverBack} alt="page0" /></Page>

    </HTMLFlipBook >
  );
}

export default MyBook;

