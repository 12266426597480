
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { apiInstance } from '../../../Utils/index';
import {
  Formik
} from 'formik';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { Suspense } from 'react';
const recaptchaRef = React.createRef();

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '0.95714286em;',
    width: '100%',
    background: '#F7F8FA',
  },
  root: {
    width: 400,
    paddingBottom: 8,
    paddingTop: 8
  },
  rootDialogBtn: {
    padding: 0,
    paddingBottom: 8,
    paddingTop: 8
  },
  textField: {
    border: 0,
    width: '100%'
  }
}));




const styles = {

};

function Contact(props) {


  const [phone, setPhone] = useState('+36 / ');
  const [messegaValue, setMessage] = useState('');
  const [messegaCounter, setMessageCounter] = useState(0);

  const [messageAreaFocus, setmessageAreaFocus] = useState(false);


  const classesSelect = useStyles();

  
  const [reVal, setReVal] = useState("")
  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();
    setReVal(token)
  }

  const handleMessageChange = (event) => {

    setMessage(event.target.value);
    setMessageCounter(event.target.value.length)

  };


  const handleFocus = (event) => {
    onSubmitWithReCAPTCHA()
    if (event.type === 'focus') {
      setmessageAreaFocus(true)
    }
    if (event.type === 'blur') {
      setmessageAreaFocus(false)
    }

  }
  const { t } = useTranslation();

  let focusClass = " ";
  if (messageAreaFocus) {
    focusClass += "focus-border";
  } else {
    focusClass = " ";
  }
  return (

    <React.Fragment>
      <Card className={classesSelect.root + "formWrapper email-form email-form--simple home-page-form"} variant="outlined" width="600px">
        <CardContent>
          <React.Fragment>
            <div className='emailFormTitle'>
              <h3 className="form--title">{t('EventPageForm.homePageFormSubtitle')}</h3>
              <p className="form--subtitle">{t('EventPageForm.eventsPagesFormTitle')}</p>
            </div>
            <Formik
              initialValues={{ email: '', name: '', subject: '' }}

              onSubmit={(values, { setSubmitting }) => {

                setSubmitting(true)

                if (reVal) {
                  //console.log(recaptchaValue)
                  var contents = '<ul><li> Név: ' + values.name + '</li><li> E-mail cím: ' + values.email + '</li><li>Telefonszám: ' + phone + '</li><li>Üzenet: ' + messegaValue + '</li></ul>'


                  apiInstance.post('/sending', {


                    content: {
                      email: values.email,
                      subject: values.subject,
                      message: contents
                    },

                  }).then(e => setMessage(''), values.name = '', values.email = '', values.subject = '', setPhone(''), setMessage(''), setReVal(''))
                }
                else {

                }



              }}

              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required(() => t('EventPageForm.requiredField')),
                name: Yup.string()
                  .required(() => t('EventPageForm.requiredField')),
                subject: Yup.string()
              })}
            >
              {(props) => {
                const {
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="muiWrapper form__input-row">
                      <TextField
                        id="outlined-helperText"
                        defaultValue="Default Value"
                        name="name"
                        variant="outlined"
                        className={classesSelect.textField + " email-form__input"}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={t('EventPageForm.name.placeholder')}
                      />


                    </div>
                    <div className="muiWrapper form__input-row">

                      <TextField
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        id="outlined-helperText2"
                        className={classesSelect.textField + " email-form__input"}
                        placeholder={t('EventPageForm.address.placeholder')}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </div>

                    <div className="muiWrapper form__input-row">
                      <TextField
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="subject"
                        className={classesSelect.textField + " email-form__input"}
                        fullWidth
                        placeholder={t('EventPageForm.subject.placeholder')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </div>

                    <div className={focusClass + " muiWrapper form__input-row form__textarea-wrapper textarea-with-button "}>
                      {/*<TextareaAutosize
                        id="muiTextArea"
                        value={messegaValue}
                        onChange={handleMessageChange}
                        onBlur={handleBlur}
                        minRows={5}
                        maxRows={6}
                        fullWidth
                        InputLabelProps={{
                          shrink: false,
                        }}
                        placeholder={t('EventPageForm.message.placeholder')}
                        className={classesSelect.auto + " email-form__input email-form__textarea"}
                      />*/}

                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={6}
                        onChange={handleMessageChange}
                        onBlur={handleFocus}
                        onFocus={handleFocus}
                        placeholder={t('EventPageForm.message.placeholder')}
                        inputProps={{ maxLength: 800 }}
                        value={messegaValue}
                        fullWidth
                        className={classesSelect.auto + " email-form__input email-form__textarea"}
                      />




                      <div className="Emailform-btn email-form__button btn-sender-homePage">
                        <div className="charCounter">
                          <span className="counter-span">{messegaCounter}/800</span>
                        </div>

                        <Button type="submit" onClick={onSubmitWithReCAPTCHA} className="btn hero-CTA-homePage btn--primary type--uppercase">
                          {t('EventPageForm.btnTitle')}
                        </Button>
                      </div>
                    </div>

                    <Suspense fallback={<h1>Loading</h1>}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_SITE_KEY}
                      />
                    </Suspense>
                  </form>
                );
              }}
            </Formik>

          </React.Fragment>

        </CardContent>
      </Card>

    </React.Fragment >
  );
}

export default withStyles(styles)(Contact);