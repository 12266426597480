//COMPONENTS START

import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import React, { Component, Suspense } from 'react';
import Sticky from 'react-sticky-el';
import ModalImage from "react-modal-image";

//COMPONENTS END


//MYCOMPONENTS START

import Aux from '../../components/Hook/Auxiliary';
import Gallery from '../../../src/components/Gallery/index';
import EmailSender from '../../../src/components/Form/EventsPagesSenderForm/emailSender';

//MYCOMPONENTS END

//IMGS START

import kisfaludy_768_08 from '../../img/768/kisfaludy-eskuvo08.jpg';
import kisfaludy_1280_08 from '../../img/1280/kisfaludy-eskuvo08.jpg';

import kisfaludy_eskuvo_512 from '../../img/512/kisfaludy-eskuvo10.jpg';
import kisfaludy_eskuvo_1680 from '../../img/1680/kisfaludy-eskuvo10.jpg';

import kisfaludy_eskuvo3_512 from '../../img/512/kisfaludy-eskuvo04.jpg';
import kisfaludy_eskuvo3_1680 from '../../img/1680/kisfaludy-eskuvo04.jpg';

//IMGS END

class Eskuvo extends Component {

    state = {
        isSticky: ''
    }

    componentDidMount() {
        if (window.innerWidth > 991) {
            this.setState({
                isSticky: true
            });
        }

        window.addEventListener('resize', () => {
            this.setState({
                isSticky: window.innerWidth > 991
            });
        }, false);
    }

    render() {
        const { t } = this.props;
        const stickyAndForm = this.state.isSticky ?
            (
                <div className="test single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                    <Sticky className="single-event__email-block" boundaryElement=".container" hideOnBoundaryHit={false} topOffset={0}>
                        <Suspense fallback={<h1>Loading</h1>}>
                            <EmailSender />
                        </Suspense>
                    </Sticky>
                </div>
            ) :
            (<div className="single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                <Suspense fallback={<h1>Loading</h1>}>
                    <EmailSender />
                </Suspense>
            </div>);
        return (
            <Aux>
                <section className="section hero-section hero-section--eskuvo">
                    <span className="filter--black filter--black--gradient"></span>
                    <div className="hero-section__content">
                        <div className="container container-xl">
                            <div className="col-12 col-md-8 col-lg-6">
                                <div className="hero-section__text">
                                    <h1 className="hero-section__title">
                                        <strong>
                                            {t("EventSinglePages.Page_Wedding.Hero_section.title")}
                                        </strong>
                                    </h1>
                                    <hr className="short" />
                                    <div className="hero-section__bodytext">
                                        <p>{t("EventSinglePages.Page_Wedding.Hero_section.introText.p1")}</p>
                                        <p>{t("EventSinglePages.Page_Wedding.Hero_section.introText.p2")}</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="bg--secondary section single-event--section">

                    <div className="container container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    {stickyAndForm}
                                    <div className="col-12 col-lg-6 mt-3 mt-md-0">
                                        <div className="section__main-content">

                                            <div className="section__image-block">
                                                {/* <img src={kisfaludy_480_08}
                                                    srcSet={`${kisfaludy_512_08} 512w, ${kisfaludy_768_08} 768w`}
        alt="Esküvő a Kisfaludy fedélzetén" />*/}
                                                <ModalImage


                                                    small={kisfaludy_768_08}
                                                    medium={kisfaludy_1280_08}
                                                    alt="Esküvő a Kisfaludy fedélzetén"
                                                />
                                            </div>


                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Wedding.BodyContent.textBlock_01.title")}
                                                </h6>
                                                <p> {t("EventSinglePages.Page_Wedding.BodyContent.textBlock_01.p1")} </p>

                                                <p> {t("EventSinglePages.Page_Wedding.BodyContent.textBlock_01.p2")}</p>
                                            </div>



                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={kisfaludy_eskuvo_512}
                                                    medium={kisfaludy_eskuvo_1680}
                                                    alt="Esküvői ajándék a Kisfaludy fedélzetén"
                                                />
                                                {/*<img src={kisfaludy_eskuvo_480}
                                                    srcSet={` ${kisfaludy_eskuvo_512} 512w, ${kisfaludy_eskuvo_768} 768w`}
    alt="Esküvői ajándék a Kisfaludy fedélzetén" />*/}
                                            </div>

                                            <div className="section__text-block">


                                                <h6 className="text-block__title">{t("EventSinglePages.Page_Wedding.BodyContent.textBlock_02.title")}</h6>

                                                <p>{t("EventSinglePages.Page_Wedding.BodyContent.textBlock_02.p1")} <b>{t("EventSinglePages.Page_Wedding.BodyContent.textBlock_02.p1-bold")}</b>
                                                    {t("EventSinglePages.Page_Wedding.BodyContent.textBlock_02.p2")}
                                                </p>

                                                <p>{t("EventSinglePages.Page_Wedding.BodyContent.textBlock_02.p3")}</p>

                                                <div className="section__image-block">
                                                    {/*} <img src={kisfaludy_eskuvo3_480}
                                                        srcSet={`${kisfaludy_eskuvo3_512} 512w, ${kisfaludy_eskuvo3_768} 768w`}
alt="Esküvői ajándék a Kisfaludy fedélzetén" />*/}
                                                    <ModalImage


                                                        small={kisfaludy_eskuvo3_512}
                                                        medium={kisfaludy_eskuvo3_1680}
                                                        alt="Esküvői ajándék a Kisfaludy fedélzetén"
                                                    />
                                                </div>

                                                <p>{t("EventSinglePages.Page_Wedding.BodyContent.textBlock_02.p4")}</p>

                                            </div>
                                        </div>
                                        <div className="autowidth backBtn">
                                            <Link
                                                to='/rendezvenyek'
                                            >


                                                <span className="btn btn--simple btn--back type--uppercase autowidth">
                                                    {t("EventSinglePages.Buttons.backToEvents")}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>
                    <div className="gallery-section">
                        <Gallery name="Wedding" />
                    </div>
                </section>
            </Aux>)
    }
}

export default withTranslation()(Eskuvo);
