
import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { apiInstance } from '../../../Utils/index';
import {
  Formik
} from 'formik';
import * as Yup from 'yup';
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DateTimePicker } from "@material-ui/pickers";
import PhoneInput from 'react-phone-input-2'
import { Suspense } from 'react';
import 'moment/locale/hu';
import { useTranslation } from 'react-i18next';

import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaProvider,

  ReCaptchaV3
} from 'react-recaptcha-x';





const recaptchaRef = React.createRef();


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  root: {
    width: 400,
    paddingBottom: 8,
    paddingTop: 8
  },
  rootDialogBtn: {
    padding: 0,
    paddingBottom: 8,
    paddingTop: 8
  },
  textField: {
    border: 0,
    background: 'white',
    width: '100%'
  },
  textFieldAutoSize: {
    rowsMin: 5,
    rowsMax: 60,
    minHeight: 100,
    ariaLabel: "maximum height",
    placeholder: "Üzenet",
    defaultValue: "Tisztelt Kisfaludy legénysége!",
  },
  phoneInput: {
    width: 100
  }
}));




const styles = {

};

function Contact(props) {

  const [selectedDateStart, handleStartDateChange] = useState(new Date());
  const [phone, setPhone] = useState('');
  const [selectedDateEnd, handleEndDateChange] = useState(new Date());

  const [selectedValue, setSelectedSubject] = useState('');
  const [messegaValue, setMessage] = useState('');
  let [selectedValueAfterSubmit, setSelectedSubjectAfterSubmit] = useState('');


  const classesSelect = useStyles();

  const [reVal, setReVal] = useState("")
  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();
    setReVal(token)
  }


  const v3Callback = (token, refreshToken) => {
    if (typeof token === 'string') {
      console.log('this is the token', token);
      if (typeof refreshToken === 'function') {
        console.log('this is the refresh token function', refreshToken);
      }
    } else {
      console.log('token retrieval in progress...');
    }
  };
  
  const handleSelectChange = (event) => {


    setSelectedSubject(event.target.value);
    selectedValueSubmitted(event.target.value);
  };

  const handleMessageChange = (event) => {


    setMessage(event.target.value);
  };

  const handleFocus = (event) => {
    onSubmitWithReCAPTCHA()
  }

  const selectedValueSubmitted = (valueState) => {
    switch (valueState) {
      case 1:
        setSelectedSubjectAfterSubmit("esküvő")
        break;
      case 2:
        setSelectedSubjectAfterSubmit("céges rendezvény")
        break;
      case 3:
        setSelectedSubjectAfterSubmit("Egyéb")
        break;
      default:
        setSelectedSubjectAfterSubmit("Egyéb")
    }
  }



  useEffect(() => {
    // Your code here
  }, []);


  moment.locale("hu");
  const { t } = useTranslation();
  return (

    <React.Fragment>
      <Card className={classesSelect.root + "formWrapper email-form email-form--aside"} variant="outlined" width="600px">
        <CardContent>
          <React.Fragment>
            <div className='emailFormTitle'>
              <p className="form--subtitle">{t('EventPageForm.eventsPagesFormSubtitle')}</p>
              <h3 className="form--title">{t('EventPageForm.eventsPagesFormTitle')}</h3>
            </div>
            <Formik
              initialValues={{ email: '', name: '', subject: '', message: '' }}
              onSubmit={(values, { setSubmitting }) => {


                if (reVal) {
                  setSubmitting(true);

                  const startTime = moment(selectedDateStart).format('YYYY.MM.DD HH:MM');
                  const endTime = moment(selectedDateEnd).format('YYYY.MM.DD HH:MM');

                  selectedValueSubmitted(selectedValue);

                  var contents = '<ul><li> Név: ' + values.name + '</li><li> E-mail cím: ' + values.email + '</li><li>Telefonszám: ' + phone + '</li><li> Foglalás kezdete: ' + startTime + '</li><li>Foglalás vége: ' + endTime + '</li><li>Üzenet: ' + messegaValue + '</li></ul>'


                  apiInstance.post('/sending', {


                    content: {
                      email: values.email,
                      subject: selectedValueAfterSubmit,
                      message: contents
                    },

                  }).then(e => setSelectedSubject(''), setMessage(''), values.name = '', values.email = '', setPhone(''))
                } else {

                }


                recaptchaRef.current.execute();
              }}

              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(() => t('EventPageForm.emailFormat'))
                  .required(() => t('EventPageForm.requiredField')),
                name: Yup.string()
                  .required(() => t('EventPageForm.requiredField')),
                phone: Yup.string(),
                message: Yup.string(),
                start: Yup.string(),
                end: Yup.string(),
                subject: Yup.string(),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="muiWrapper">
                      <TextField
                        id="standard-basic-name"
                        label={t('EventPageForm.name.label')}
                        name="name"
                        className={classesSelect.textField + " email-form__input"}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.name && touched.name) && errors.name}
                        margin="normal"

                      />
                    </div>
                    <div className="muiWrapper">
                      <TextField
                        id="standard-basic-email"
                        error={errors.email && touched.email}
                        label={t('EventPageForm.address.label')}
                        name="email"
                        className={classesSelect.textField + " email-form__input"}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={(errors.email && touched.email) && errors.email}
                        margin="normal"

                      />
                    </div>
                    <div className="phone--input">
                      <PhoneInput
                        country={'hu'}
                        value={phone}
                        onChange={setPhone}
                        regions={'europe'}
                        className={classesSelect.phoneInput}
                        width={100}
                        disableSearchIcon
                      />
                    </div>
                    <div className="muiWrapper">
                      <FormControl className={classesSelect.formControl + " email-form__input"}>
                        <InputLabel htmlFor="grouped-select">{t('EventPageForm.subject.label')}</InputLabel>
                        <Select defaultValue="" id="grouped-select" value={selectedValue} onChange={handleSelectChange}>
                          <ListSubheader>{t('EventPageForm.eventsPagesFormSubjectTitle1')}</ListSubheader>
                          <MenuItem value={1}>{t('EventPageForm.eventsPagesFormSubjectOption1')}</MenuItem>
                          <MenuItem value={2}>{t('EventPageForm.eventsPagesFormSubjectOption2')}</MenuItem>
                          <ListSubheader>{t('EventPageForm.eventsPagesFormSubjectTitle2')}</ListSubheader>
                          <MenuItem value={3}>{t('EventPageForm.eventsPagesFormSubjectOption3')}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="timeTable">
                      <Fragment>
                        <DateTimePicker
                          autoOk
                          ampm={false}
                          label={t('EventPageForm.eventsPagesFormTimeStartField')}
                          inputVariant="outlined"
                          name={values.start}
                          value={selectedDateStart}
                          onChange={handleStartDateChange}
                        /><DateTimePicker
                          name={values.end}
                          autoOk
                          ampm={false}
                          label={t('EventPageForm.eventsPagesFormTimeEndField')}
                          inputVariant="outlined"
                          value={selectedDateEnd}
                          onChange={handleEndDateChange}
                        /></Fragment></div>

                    <TextareaAutosize
                      value={messegaValue}
                      onChange={handleMessageChange}
                      minRows={3}
                      maxRows={50}
                      placeholder={t('EventPageForm.message.placeholder')}
                      onBlur={handleFocus}
                      onFocus={handleFocus}
                    />

                    <div className="Emailform-btn email-form__button">
                      <Button type="submit" className="btn btn--primary type--uppercase">
                        {t('EventPageForm.btnTitle')}
                      </Button>
                    </div>
                    <Suspense fallback={<h1>Loading</h1>}>
                     
                    
                    <ReCaptchaProvider
                     
                      siteKeyV3={process.env.REACT_APP_SITE_KEY}
                      langCode="en"
                      hideV3Badge={false}
                    >
                    
                      <ReCaptchaV3
                        callback={v3Callback}
                        theme={EReCaptchaV2Theme.Light}
                        size={EReCaptchaV2Size.Normal}
                        id="my-id"
                        data-test-id="my-test-id"
                        tabindex={0}
                      />
                      <ReCaptchaV3 action="your-action" callback={v3Callback} />
                    </ReCaptchaProvider>
                    </Suspense>
                  </form>
                );
              }}
            </Formik>

          </React.Fragment>

        </CardContent>
      </Card>

    </React.Fragment >
  );
}

export default withStyles(styles)(Contact);