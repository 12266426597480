import React,{Component} from 'react';
import { withTranslation } from 'react-i18next';

import logo_175_ribbon_v2 from '../../img/graphic_assets/logo-175-ribbon2.png';

class Stripe175 extends Component{
  
    render(){
        const { t } = this.props;
    return (
        <section className="section section--anniversary">
        <div className="container container-xl">
            <div className="section-content__wrapper row justify-content-center">

                <div className="col-12 col-md-11 col-lg-7">
                    <div className="anniversary__image-container">
                        <img alt='anniversary' src={logo_175_ribbon_v2} />            
                    </div>
                    <div className="anniversary__text-container">
                        <h3 className="anniversary__title">{t("Anniversary_175_stripe.title")}</h3>

                    </div>
                </div>

            </div>
        </div>
    </section>
    )}
  }
  
  export default withTranslation()(Stripe175);