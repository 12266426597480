import axios from 'axios';

export const checkUserIsAdmin = currentUser => {
  if (!currentUser || !Array.isArray(currentUser.userRoles))
  return false;
  const { userRoles } = currentUser;
  if (userRoles.includes('admin')) return true;

  return false;
}
  

export const apiInstance = axios.create({
 baseURL:`https://${process.env.REACT_APP_UNSPLASH_KEY}.cloudfunctions.net/api`
});