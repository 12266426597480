import { Button,Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function MyVerticallyCenteredModal(props) {

  const { t } = useTranslation();
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="modal-title-custom"> {t('Tickets_section.AdditionalInfo_section.modalTitle')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="paragraph">
         {t('Tickets_section.AdditionalInfo_section.p2')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="modal-btn margint-top-modal" onClick={props.onHide}><span> {t('Tickets_section.AdditionalInfo_section.modalBtn')}</span></Button>
        </Modal.Footer>
      </Modal>
    );
  }
  export default MyVerticallyCenteredModal
  
