//COMPONENTS
import React, { Component, Suspense } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ModalImage from "react-modal-image";
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import Iframe from 'react-iframe'
//COMPONENTS END

//MYCOMPONENTS
import Aux from '../../src/components/Hook/Auxiliary/index'
import ModalVertical from '../components/Modal/modal';
import Accordion from '../components/Accordion/Accordion';
import Gallery from '../components/Gallery/index';
import EmailSender from '../components/Form/HomePageForm/emailSender';
//ENDMYCOMPONENTS

//IMGS START

//1.ICONS
import lemIcon from '../img/icons/icon-lemonade.svg';
import coctailIcon from '../img/icons/icon-cocktail.svg';
import coffeeIcon from '../img/icons/icon-coffee.svg';
import infoIcon from '../img/icons/information.svg';
import wineIcon from '../img/icons/icon-wine.svg';

//2.PHOTOS
//import kisfaludy_bar from '../img/koktelok_016.jpg';
import kisfaludy_bar2 from '../img/koktelok_013_v2.jpg';

import kisfaludy_480_18 from '../img/480/kisfaludy-480-18.jpg';
import kisfaludy_512_18 from '../img/512/kisfaludy-512-18.jpg';
import kisfaludy_768_18 from '../img/768/kisfaludy-768-18.jpg';

import kisfaludy_480_28 from '../img/480/kisfaludy-480-28.jpg';
import kisfaludy_512_28 from '../img/512/kisfaludy-512-28.jpg';
import kisfaludy_768_28 from '../img/768/kisfaludy-768-28.jpg';

import sr_768_25 from '../img/768/sr-768-25.jpg';

import kisfaludy_768_08 from '../img/768/kisfaludy-768-08.jpg';
import kisfaludy_1680_08 from '../img/1680/kisfaludy-1680-08.jpg';

import kisfaludy_768_27 from '../img/768/kisfaludy-768-27.jpg';
import kisfaludy_1680_27 from '../img/1680/kisfaludy-1680-27.jpg';

import kisfaludy_768_09 from '../img/768/kisfaludy-768-09.jpg';
import kisfaludy_1680_09 from '../img/1680/kisfaludy-1680-09.jpg';


import kisfaludy_768_05 from '../img/768/kisfaludy-768-05.jpg';
import kisfaludy_1680_05 from '../img/1680/kisfaludy-1680-05.jpg';

import writerMachine512 from "../img/512/email-section-bckgrnd-01-512x.jpg";
import writerMachine768 from "../img/768/email-section-bckgrnd-01-768x.jpg";

import route_map_01 from '../img/graphic_assets/route-map-01.svg';

//3.LOGOS
import logo_175 from '../img/graphic_assets/logo-175.png';
import logo_175_ribbon from '../img/graphic_assets/logo-175-ribbon.png';

//IMGS END

//VIDEOS START
import kisfaludy_promo_compact from '../../src/video/Kisfaludy_promo-compact.mp4';
import kisfaludy_promo from '../../src/video/Kisfaludy_promo.webm';
//VIDEOS END


var data = {
    mydata: {
        imgs: [
            {
                name: "logo_175",
                src: logo_175,
                alt: "175 éves a Kisfaludy Lapátkerekes Gőzhajó logó"
            },
            {
                name: "logo_175_ribbon",
                src: logo_175_ribbon,
                alt: "175 éves a Kisfaludy Lapátkerekes Gőzhajó szalag ábra"
            },
            {
                name: "kisfaludy_28",
                src: kisfaludy_480_28,
                srcset: `${kisfaludy_512_28} 512w, ${kisfaludy_768_28} 768w`,

            }
        ]
    }
};


class HomePage extends Component {



    constructor() {
        super();
        //this.showEmailDelay = this.showEmailDelay.bind(this);
        this.myVideoPlayer = this.myVideoPlayer.bind(this);
        this.scrollToLocation = this.scrollToLocation.bind(this);
        this.state = {
            width: 0,
            height: 0,
            modalState: false
        }
    }



    FetchImage(dataSetName, imgName) {

        switch (dataSetName) {

            case "imgs":
                var element = data.mydata.imgs.find(item => item.name === imgName);
                return element;

            default:
                break;
        }
    }


    firstSchroll() {

        const { hash } = window.location;
        console.log(hash)
        if (hash !== '') {
            let retries = 0;
            const id = hash.replace('#', '');
            const scroll = () => {
                retries += 0;
                if (retries > 50) return;
                const element = document.getElementById(id);
                if (element) {
                    setTimeout(() => element.scrollIntoView({ behavior: "smooth", block: "center" }), 50);
                } else {
                    setTimeout(scroll, 50);
                }
            };
            scroll();
        }
    }


    myVideoPlayer() {
        var sectionClass = "video--section"
        var videoClass = "myvideoplayer-video";
        var previewPicClass = "video__preview"
        var btnClass = "video__btn";
        var videoRunning = false;

        $("." + sectionClass).click(function () {

            if (videoRunning) {
                videoRunning = false;;
                $("." + sectionClass).removeClass("videoplaying");
                $("." + btnClass).removeClass("hiding");
                $("." + btnClass).addClass("play");
                $("." + videoClass).get(0).pause();
            } else {
                videoRunning = true;
                $("." + sectionClass).addClass("videoplaying");
                $("." + previewPicClass).addClass("hiding")
                $("." + btnClass).addClass("hiding");
                $("." + btnClass).removeClass("play");
                $("." + videoClass).get(0).play();
            }

        });
    }

    updateDimensions() {

        if (window.innerWidth > 750 && window.innerWidth < 1200) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 1.5);
            this.setState({ width: update_width, height: update_height });
        }
        else if (window.innerWidth > 1200 && window.innerWidth < 1300) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.2);
            this.setState({ width: update_width, height: update_height });
        }
        else if (window.innerWidth > 1300 && window.innerWidth < 1400) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.3);
            this.setState({ width: update_width, height: update_height });
        }
        else if (window.innerWidth > 1400 && window.innerWidth < 1500) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.4);
            this.setState({ width: update_width, height: update_height });
        }
        else if (window.innerWidth > 1500 && window.innerWidth < 1600) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.5);
            this.setState({ width: update_width, height: update_height });
        }
        else if (window.innerWidth > 1600) {
            let update_width = window.innerWidth;
            let update_height = window.innerHeight;
            this.setState({ width: update_width, height: update_height });
        }
        else {
            let update_width = 100;
            let update_height = 100;
            this.setState({ width: update_width, height: update_height });
        }
    }

    setModalState = (e) => {

        if (this.state.modalState) {
            this.setState({ modalState: false });
        } else {
            this.setState({ modalState: true });
        }
    }

    scrollToLocation = () => {
        const { hash } = window.location;
        if (hash !== '') {
            let retries = 0;
            const id = hash.replace('#', '');
            const scroll = () => {
                retries += 0;
                if (retries > 50) return;
                const element = document.getElementById(id);
                if (element) {
                    setTimeout(() => element.scrollIntoView({ behavior: "smooth", block: "center" }), 100);
                } else {
                    setTimeout(scroll, 50);
                }
            };
            scroll();
        }
    }

    componentDidMount() {
        //  this.showEmailDelay()
        window.onload = function (event) {
            // console.log(event.target.baseURI)
        };
        this.myVideoPlayer()
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        window.addEventListener("load", this.scrollToLocation.bind(this));
        this.scrollToLocation();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        window.removeEventListener("load", this.scrollToLocation.bind(this));
    }


    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {

            // console.log("this.props:", this.props.location)

            //  console.log("prev.props:", prevProps.location)
            this.scrollToLocation();
        }
    }
    render() {

        const { t } = this.props;
        let videoStyle = {};

        // console.log(this.props.i18n.language)

        this.state.width > 750 ? (videoStyle = {
            height: this.state.height,
            width: this.state.width,
        }) : (videoStyle = { height: this.state.height + '%', width: this.state.width + '%' });


        const langTrick = this.props.i18n.language ? (this.props.i18n.language === 'en' ? (
            <div className="schedule__time text-orientation-wo-pd text-container-types">

                <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.wine')}</span>
                <div className="subtext-types-wrapper">
                    <span className="subtext-types2">{t('TimeTable_section.Temathic_group.wine_guess')}</span>
                    <span className="subtext-types">
                        {t('TimeTable_section.Temathic_group.bold_wine')} </span>


                    <span className="subtext-types2">{t('TimeTable_section.Temathic_group.or')}</span>
                    <span className="subtext-types">{t('TimeTable_section.Temathic_group.bold_ice_tea')}</span>


                </div>
            </div>) : (<div className="schedule__time text-orientation-wo-pd text-container-types">

                <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.wine')}</span>
                <div className="subtext-types-wrapper">
                    <span className="subtext-types">
                        {t('TimeTable_section.Temathic_group.bold_wine')} </span>
                    <span className="subtext-types2">{t('TimeTable_section.Temathic_group.or')}</span>
                    <span className="subtext-types">{t('TimeTable_section.Temathic_group.bold_ice_tea')}</span>
                    <span className="subtext-types2">{t('TimeTable_section.Temathic_group.wine_guess')}</span>

                </div>
            </div>)) : (<div></div>)
        return (
            <Aux>
                <section className="hero-section hero--home hero-wrapper">
                    <div className="filter"></div>

                    <div className="hero__content">

                        <div className="container container-xl">

                            <div className="row justify-content-between align-items-center hero-margin">

                                <div className="hero__logo col-10 col-md-8 col-lg-5">

                                    <a href="/tortenelem">
                                        <img src={logo_175} alt='logo' />
                                    </a>
                                </div>

                                <div className="hero__text col-12 col-lg-7 pl-lg-5 align-items-left">
                                    <h1 className="hero__title">{t('Hero.title')}
                                    </h1>
                                    <hr className="short" />
                                    <p className="hero__subtitle">
                                        {t('Hero.subtitle1')} <span
                                            className="break--largescreen">{t('Hero.subtitle2')}</span>
                                    </p>
                                    <Link className="btn hero-CTA btn--primary type--uppercase" smooth to="/#bemutatkozas" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}> {t('Hero.buttonTitle')} </Link>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <section className="notification--section firework--section bg--maincol">
                    {/*  <div className="container container-xl">
                <div className="row justify-content-center align-content-center">
                    <div className="col-12">
                        
                        <div className="notification__content">
                            <div className="notification__bodytext">
                                <div className="align-items-lg-center container">
                                    <div className="row text-center fire-wrapper">
                                        <div className="col-md-9 col-12"><p className="fireworkText">Nézd végig a tihanyi és balatonfüredi tűzijátékot a Kisfaludy fedélzetéről <b>augusztus 20-án!</b><br/>
Válts jegyet akár elővételben a <b>Kisfaludy Kasszánál.</b> Beszállás 20.30-tól, indulás 21.00.</p></div>
                                     <div className="d-flex justify-content-center align-items-center col-md-3 col-12 w-100"><a target="_blank" className="fireworkBtn" href="https://kisfaludygozos.hu/tuzijatek/aug20_tajekoztato.pdf">További információ</a></div> 
                                    </div>                                    
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
        </div>*/}
                </section>
                <section className="section bg--secondary introduction--section">



                    <div className="container container-xl">

                        <div className="row justify-content-between align-items-center" id="bemutatkozas">
                            <div className="col-12 col-md-12 boxed boxed--border boxed--shadow">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    <div className="boxed__pic-container col-12 col-md-6 mt-3 mt-md-0 order-2">
                                        <div className="boxed__pic__modal">
                                            <ModalImage
                                                small={kisfaludy_768_08}
                                                large={kisfaludy_1680_08}
                                                alt="Kisfaludy sétahajó a Tihanyi Apátság közelében"
                                            />
                                        </div>
                                    </div>
                                    <div className="boxed__text col-12 col-md-6 pb-3 pb-md-0 order-1 order-md-1 main-card-padding">
                                        <span className="boxed__title">
                                            <strong>{t('Introduction_section.boxTitle')}</strong>
                                        </span>

                                        <hr className="short" />
                                        <div className="boxed__bodytext">
                                            <p>{t('Introduction_section.bodyText.p1')}</p>
                                            <p>{t('Introduction_section.bodyText.p2')}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div id="bemutatkozas" className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12 boxed boxed--border boxed--shadow">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    <div className="boxed__pic-container col-12 col-md-6 order-2 order-md-1">
                                        <div className="boxed__pic__modal">
                                            <ModalImage
                                                small={kisfaludy_bar2}
                                                large={kisfaludy_bar2}
                                                alt="Kisfaludy Bár italválasztéka"
                                            />
                                        </div>
                                    </div>
                                    <div className="boxed__text col-12 col-md-6 pb-3 pb-md-0 order-1 order-md-2 main-card-padding">
                                        <div className="relative-wrapper">
                                            <span className="helper"></span>
                                            <img className="icons-class bar_pikto home-icon-class" alt="cocktailicon" src={coctailIcon} />
                                            <span className="boxed__title kisfaludybar-title">
                                                <strong>{t('Bar_section.boxTitle')}</strong>
                                            </span></div>

                                        <hr className="short" />
                                        <div className="boxed__bodytext">
                                            <p>{t('Bar_section.bodyText.p1')}</p>

                                            <div className="text-center">
                                                <Link
                                                    to='/kisfaludybar' className="btn type--uppercase autowidth"
                                                >{t("ShortlyAboutKisfaludy_section.buttonText")}
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<!-- Egyedi Rendezvények -->*/}

                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12 boxed boxed--border boxed--shadow">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    <div className="boxed__pic-container col-12 col-md-6 mt-3 mt-md-0 order-2">
                                        <div className="boxed__pic__modal">
                                            <ModalImage
                                                small={kisfaludy_768_27}
                                                large={kisfaludy_1680_27}
                                                alt="A Kisfaludy gőzös fedélzete ünnepi fényekben"
                                            />
                                        </div>
                                    </div>
                                    <div className="boxed__text col-12 col-md-6 pb-3 pb-md-0 order-1 main-card-padding">
                                        <span className="boxed__title">
                                            <strong>{t('UniqueEvents_section.boxTitle')}</strong>
                                        </span>

                                        <hr className="short" />
                                        <div className="boxed__bodytext">
                                            <p>{t('UniqueEvents_section.bodyText.p1')}
                                            </p>
                                            <div className="text-center">
                                                <Link
                                                    to='/rendezvenyek' className="btn type--uppercase autowidth"

                                                >
                                                    {t('UniqueEvents_section.buttonText')}
                                                </Link>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12 boxed boxed--border boxed--shadow">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    <div className="boxed__pic-container col-12 col-md-6 order-2 order-md-1">
                                        <div className="boxed__pic__modal">
                                            <ModalImage
                                                small={kisfaludy_768_09}
                                                large={kisfaludy_1680_09}
                                                alt="Kisfaludy sétahajó szalontér"
                                            />
                                        </div>
                                    </div>

                                    <div className="boxed__text col-12 col-md-6 pb-3 pb-md-0 order-1 order-md-2 main-card-padding">
                                        <span className="boxed__title boxed__title">
                                            <strong>{t('TimeTravel_section.boxTitle')}</strong>
                                        </span>

                                        <hr className="short" />
                                        <div className="boxed__bodytext">
                                            <p>{t('TimeTravel_section.bodyText.p1')}</p>
                                            <p>{t('TimeTravel_section.bodyText.p2.1.1')}<strong>{t('TimeTravel_section.bodyText.p2.strong')}</strong>{t('TimeTravel_section.bodyText.p2.1.2')}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Anniversary 175 section */}
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12 boxed boxed--border boxed--shadow">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    <div className="boxed__pic-container col-12 col-md-6">
                                        <div className="boxed__pic__modal boxed__overview">
                                            <ModalImage
                                                small={kisfaludy_768_28}
                                                large={kisfaludy_768_28}
                                                alt="Kisfaludy sétahajó a balatonfüredi kikötő közelében"
                                            />

                                        </div>
                                    </div>
                                    <div className="boxed__text col-12 col-md-6 pb-3 pb-md-0 order-first main-card-padding">
                                        <span className="boxed__title">
                                            <strong>{t("Anniversary_175_section.boxTitle")}</strong>
                                        </span>
                                        <hr className="short" />
                                        <div className="boxed__bodytext">
                                            <p>{t("Anniversary_175_section.bodyText")}</p>
                                            <div className="text-center">
                                                <Link
                                                    to='/tortenelem/#anniversary-175' className="btn type--uppercase autowidth"
                                                >{t("ShortlyAboutKisfaludy_section.buttonText")}
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div>

                </div>

                <section className="tickets--section" id="menetrendjegyarak">
                    <div className="container container-xl">
                        <div className="row justify-content-center justify-content-lg-between padding-bottom-test">



                            <div className="col-12 col-lg-8">


                                {/* Schedule panel */}

                                <div className="title-wrapper-tickets">

                                    <div className="title-1 title-heights schedule__header box-header--copy2 bg--dark pd-bottom">
                                        <h3>{t('Tickets_section.boxTitle1')}</h3>
                                        <span className="subtitle-span">Hétfőtől szerdáig járataink nem indulnak</span>
                                    </div>

                                </div>

                              
                                <div className="flex-test-container">

                                    <div className="boxed boxed-wo-bottom-padding boxed--style1 max-width-test right-radius-and-right-border">


                                        <div className="boxed-content no-top-bottom no-sides-padding">
                                            <div className="schedule-timetable">

                                                <div className="schedule-timetable__item first-block first-row">
                                                    <span className="schedule__time text-orientation">{t('TimeTable_section.Temathic_group.title')}</span>
                                                </div>
                                                <div className="schedule-timetable__item standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="coffeIcon" src={coffeeIcon} />
                                                    </div>
                                                    <div className="schedule__time text-orientation-wo-pd text-container-types">

                                                        <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.cappucchino')} </span>
                                                        <div className="subtext-types-wrapper"><span className="subtext-types">{t('TimeTable_section.Temathic_group.fifty')}</span><span className="subtext-types2"> {t('TimeTable_section.Temathic_group.capText')}</span></div></div>
                                                </div>

                                                <div className="schedule-timetable__item standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="lemonicon" src={lemIcon} />
                                                    </div>

                                                    <div className="schedule__time text-orientation-wo-pd text-container-types">

                                                        <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.fresh')}</span>
                                                        <div className="subtext-types-wrapper"><span className="subtext-types">{t('TimeTable_section.Temathic_group.fifty')}</span><span className="subtext-types2"> {t('TimeTable_section.Temathic_group.freText')}</span></div></div>
                                                </div>

                                                <div className="schedule-timetable__item  standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="coffeIcon" src={coffeeIcon} />
                                                    </div>
                                                    <div className="schedule__time text-orientation-wo-pd text-container-types">

                                                        <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.cappucchino')}</span>
                                                        <div className="subtext-types-wrapper"><span className="subtext-types">{t('TimeTable_section.Temathic_group.fifty')}</span><span className="subtext-types2"> {t('TimeTable_section.Temathic_group.capText')}</span></div></div>
                                                </div>

                                                <div className="schedule-timetable__item standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="lemonicon" src={lemIcon} />
                                                    </div>

                                                    <div className="schedule__time text-orientation-wo-pd text-container-types">

                                                        <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.fresh')}</span>
                                                        <div className="subtext-types-wrapper"><span className="subtext-types">{t('TimeTable_section.Temathic_group.fifty')}</span><span className="subtext-types2"> {t('TimeTable_section.Temathic_group.freText')}</span></div></div>
                                                </div>

                                                {/* utolsó járat 20:30  <div className="schedule-timetable__item standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="cocktailicon" src={coctailIcon} />

                                                    </div>




                                                    <div className="schedule__time text-orientation-wo-pd text-container-types">

                                                        <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.coctail')}</span>
                                                        <div className="subtext-types-wrapper"><span className="subtext-types">{t('TimeTable_section.Temathic_group.fifty')}</span><span className="subtext-types2"> {t('TimeTable_section.Temathic_group.cocText')}</span>
                                                        </div>
                                                    </div>
    </div>*/}

                                                <div className="schedule-timetable__item standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="cocktailicon" src={wineIcon} />

                                                    </div>




                                                    {langTrick}

                                                </div>

                                                <div className="schedule-timetable__item standard-padding-titles all-row">
                                                    <div className="icon-wrapper">
                                                        <img className="icons-class" alt="cocktailicon" src={coctailIcon} />

                                                    </div>




                                                    <div className="schedule__time text-orientation-wo-pd text-container-types">

                                                        <span className="schedule__time text-orientation-wo-pd">{t('TimeTable_section.Temathic_group.coctail')}</span>
                                                        <div className="subtext-types-wrapper"><span className="subtext-types">{t('TimeTable_section.Temathic_group.fifty')}</span><span className="subtext-types2"> {t('TimeTable_section.Temathic_group.cocText')}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>




                                    <div className="boxed boxed-wo-bottom-padding boxed--style1 max-width-test left-radius-test">


                                        <div className="boxed-content no-top-bottom no-sides-padding">
                                            <div className="schedule-timetable">
                                                <div className="schedule-timetable__item  first-block first-row subtitle-table flex-test">
                                                    <span className="subtitle-span">Csütörtök - Vasárnap</span>
                                                    <span className="time-row">{t('TimeTable_section.col2SubTitleStandard')}</span>
                                                </div>

                                                <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test">
                                                        <span className="schedule__time">11:00</span>
                                                    </div>

                                                </div>

                                                <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test">
                                                        <span className="schedule__time">12:30</span>
                                                    </div>
                                                </div>

                                                <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test">
                                                        <span className="schedule__time">15:00</span>
                                                    </div>
                                                </div>

                                                <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test">
                                                        <span className="schedule__time">16:30</span>
                                                    </div>
                                                </div>
                                                <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test">
                                                        <span className="schedule__time">18:00</span>
                                                    </div>
                                                </div>

                                                <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test flex-column">
                                                        <span className="schedule__time">19:30</span>
                                                    </div>
                                                </div>
                                                {/* <div className="schedule-timetable__item flex-test-parent all-row">
                                                    <div className="flex-test flex-column">
                                                        <span className="schedule__time">20:30</span>
                                                    </div>
    </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* Info Bar */}
                                <div className="boxed boxed--style-info">
                                    <div className="boxed-content no-top-bottom no-sides-padding style-info-wrapper">
                                        <div className="icon-wrapper-info">
                                            <img className="icons-class-info" alt="infoicon" src={infoIcon} />
                                        </div>
                                        <div className="info-text-wrapper">
                                            <span>{t('Tickets_section.Info_section.p1')}<strong>{t('Tickets_section.Info_section.p2_span')}</strong>{t('Tickets_section.Info_section.p3')}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="tickets-box col-12 col-lg-4">
                                <div className="boxed boxed--border boxed--shadow boxed--style1">
                                    <div className="tickets__header title-heights box-header bg--dark">
                                        <h3 className="tickets-title">{t('Tickets_section.boxTitle2')}</h3>
                                    </div>



                                    <div className="boxed-content no-top-bottom">

                                        <div className="tickets-block">


                                            <div className="tickets-block__item">
                                                <span className="ticket__details">
                                                    <h3 className="ticket__title">{t('Tickets_section.bodyText.subtitle1')}</h3>
                                                    <p className="ticket__addendum"></p>
                                                </span>
                                                <span className="ticket__price">
                                                    <h3>2.800 Ft</h3>
                                                </span>
                                            </div>



                                            <div className="tickets-block__item">
                                                <span className="ticket__details">
                                                    <h3 className="ticket__title">{t('Tickets_section.bodyText.subtitle2')}</h3>
                                                    <p className="ticket__addendum">{t('Tickets_section.row2Text')}</p>
                                                </span>
                                                <span className="ticket__price">
                                                    <h3>1.500 Ft</h3>
                                                </span>
                                            </div>


                                            <div className="tickets-block__item">
                                                <span className="ticket__details">
                                                    <h3 className="ticket__title">{t('Tickets_section.bodyText.subtitle3')}</h3>
                                                    <p className="ticket__addendum">{t('Tickets_section.row3Text')}</p>
                                                </span>
                                                <span className="ticket__price">
                                                    <h3>8.000 Ft</h3>
                                                </span>
                                                {/* Sub param */}
                                                <span className="ticket__details ticket__sub-param">
                                                    <h4 className="sub-param__title">{t('Tickets_section.row4Text')}</h4>
                                                </span>

                                                <span className="ticket__price ticket__sub-param">
                                                    <h4>+ 1.000 Ft</h4>
                                                </span>
                                                {/* End of Sub param */}

                                            </div>

                                            <div className="tickets-block__item">
                                                <span className="ticket__details">
                                                    <h3 className="ticket__title">{t('TimeTable_section.Temathic_group.wine')}</h3>
                                                </span>
                                                <span className="ticket__price">
                                                    <h3>3.000 Ft</h3>
                                                </span>

                                                {/* Sub param */}
                                                <span className="ticket__details ticket__sub-param">

                                                    <p className="ticket__addendum">{t('TimeTable_section.childrensDesc')}</p>
                                                </span>

                                                <span className="ticket__price ticket__sub-param">

                                                </span>
                                                {/* End of Sub param */}

                                                {/* Sub param */}
                                                <span className="ticket__details ticket__sub-param">
                                                    <h4 className="sub-param__title">{t('TimeTable_section.childrensDesc2')}</h4>
                                                    {/* <p className="ticket__addendum">A jegyhez 1 db ingyenes jegestea jár</p> */}
                                                </span>

                                                <span className="ticket__price ticket__sub-param">
                                                    <h4> 9.000 Ft</h4>
                                                </span>
                                                {/* End of Sub param */}

                                            </div>

                                            {/*  <div className="tickets-block__item tickets-btn-container">
                                                <div className="buy-tickets-btn-wrapper">

                                                    <a rel="noreferrer" href="https://ticket.kisfaludygozos.hu/kisfaludygozos" target="_blank" className="btn hero-CTA btn--primary type--uppercase" >{t('Tickets_section.btn')} </a>
                                                </div>
        </div>*/}
                                            <div className="tickets-block__item">
                                                <div className="text-type">
                                                    <div className="icon-wrapper-info weather-block">
                                                        <img className="icons-class-info" alt="infoicon" src={infoIcon} />
                                                    </div>


                                                    <div className="info-wrapper">

                                                        <div className="info-text-wrapper"><p className="info-text">{t('Tickets_section.AdditionalInfo_section.p1')}

                                                        </p></div></div>




                                                </div>
                                            </div>
                                            <div className="modal-wrapper">

                                                <Button className="modal-btn margint-top-modal" variant="primary" onClick={this.setModalState}>
                                                    <div className="text-wapper"><span className="more-info-btn-text"> {t('Tickets_section.AdditionalInfo_section.firstBtn')}</span></div>

                                                    <div className="right-arrow-wrapper">
                                                        <div className="right-arrow-container"><i className="fas fa-arrow-right middle"></i></div></div>


                                                </Button>
                                            </div>



                                        </div>

                                    </div>




                                </div>


                            </div>

                        </div>
                    </div>
                    <ModalVertical
                        show={this.state.modalState}
                        onHide={this.setModalState}
                    />
                </section>

                <section className="map--section bg--lightm border--light border-top-sm">
                    <div className="container container-xl">
                        <div className="row justify-content-stretch justify-content-lg-between align-items-stretch">
                            <div className="col-12 col-lg-6 align-self-center mb-4 mb-md-0 order-1 order-lg-2">
                                <div className="map-section__text">
                                    <h1><strong>{t("Map_section.boxTitle")}</strong></h1>
                                    <hr className="short" />

                                    <h4><strong>{t("Map_section.subtitle")} </strong>{t("Map_section.subtitleText")}</h4>
                                    <p>{t("Map_section.bodyText.p1.1")}<strong>{t("Map_section.bodyText.p1.strong")}</strong>{t("Map_section.bodyText.p1.2")}
                                    </p>
                                </div>
                            </div>

                            <div id="terkep" className="col-12 col-lg-6 order-2 order-md-1">

                                <div className="map-section__map-container gmap">
                                    <Iframe className="iframe-map" url="https://www.google.com/maps/d/u/0/embed?mid=1zfTEN8q4zQWKyGA9mECkmsB43j_rQEQ4&z=17"
                                        width="100%"
                                        height="100%"
                                    />

                                </div>
                                <div className="map-section__notification">
                                    <span className="comment--light comment--warning centered">
                                        <p><strong>{t("Map_section.gMap.p1.1")}</strong> {t("Map_section.gMap.p1.2")}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {/*<!--end of Address-->*/}
                {/*<!--Beginning of tickets-->*/}

                <section className="section departures--section bg--lightm">
                    <div className="container container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-lg-6 pb-5 pb-lg-0">
                                <div className="departures__text-container">
                                    <h1>{t("Departures_section.boxTitle")}</h1>
                                    <hr className="short" />
                                    <p><strong>{t("Departures_section.bodyText.p1.strong")}</strong>{t("Departures_section.bodyText.p1.2")}
                                    </p>
                                    <p>
                                        <strong>{t("Departures_section.bodyText.p2")}</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="departures__img-container">
                                    <ModalImage
                                        className="departures__img"
                                        small={kisfaludy_768_05}
                                        large={kisfaludy_1680_05}
                                        alt="A Kisfaludy Kassza a jegyváltási pontok egyikén"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section ship-route--section">
                    <div className="container container-xl">
                        <div className="row justify-content-center justify-content-lg-between align-items-lg-center">
                            <div className="col-12 col-lg-6">
                                <div className="ship-route__map">
                                    <ModalImage

                                        className="departures__img"
                                        small={route_map_01}
                                        large={route_map_01}
                                        alt="A Kisfaludy Lapátkerekes Gőzhajó útvonala"
                                    />
                                    {/*<img src={route_map_01}
                                        alt="A Kisfaludy Lapátkerekes Gőzhajó útvonala" />*/}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 pt-5 pt-lg-0">
                                <div className="ship-route__text">
                                    <h1 className="ship-route__title">{t("ShipRoute_section.boxTitle")}</h1>
                                    <hr className="short" />
                                    <p className="ship-route__subtitle">{t("ShipRoute_section.subtitle")}</p>
                                    <ul className="ship-route__list">
                                        <li>
                                            <i className="route-point gramophone"></i>
                                            <p>{t("ShipRoute_section.bodyText.p1")}</p>
                                        </li>
                                        <li>
                                            <i className="route-point coffee"></i>
                                            <p>{t("ShipRoute_section.bodyText.p2")}</p>
                                        </li>
                                        <li>
                                            <i className="route-point photo"></i>
                                            <p>{t("ShipRoute_section.bodyText.p3")}</p>
                                        </li>
                                        <li>
                                            <i className="route-point wine"></i>
                                            <p>{t("ShipRoute_section.bodyText.p4")}</p>
                                        </li>
                                    </ul>
                                    <p className="text-center">{t("ShipRoute_section.bodyText.p5")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="video--section" style={videoStyle}>
                    <div className="video__container" >
                        <video className="myvideoplayer-video" >
                            <source src={kisfaludy_promo_compact} type="video/mp4" />
                            <source src={kisfaludy_promo} type="video/webm" />
                        </video>
                        <span className="video__preview"></span>

                    </div>


                    <div className="video__title-box">

                        <span className="video__btn play"></span>
                    </div>

                </section>

                {/*<!-- end of video section -->*/}

                {/*<!--beginning of gallery section-->*/}

                <Suspense fallback={<h1>Loading</h1>}>
                    <Gallery name="Homepage" />
                </Suspense>
                {/*<!--End of Gallery-->*/}

                {/*<!--FAQ section-->*/}
                <section className="section faq--section pt-5">
                    <span id="gyik"></span>
                    <div className="container container-xl">
                        <div className="switchable__text mb-5">
                            <div className="text-center">
                                <h1>{t("Accord_section.boxTitle")}</h1>
                            </div>
                        </div>

                        <div className="row justify-content-center justify-content-lg-start align-items-stretch">

                            <div className="question-row col-12 col-md-10 col-lg-6">
                                <Suspense fallback={<h1>Loading</h1>}>
                                    <Accordion />
                                </Suspense>
                            </div>
                            <div className="col-12 col-md-10 col-lg-6">
                                <div className="faq__img">

                                    <img src={kisfaludy_480_18}
                                        srcSet={`${kisfaludy_512_18} 512w, ${kisfaludy_768_18} 768w`}
                                        alt="A Kisfaludy fedélzete téged is vár!" />

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="kapcsolat" className="section bg--secondary introduction--section home-page-sender-form-section">
                    <div className="container container-xl form-wrapper">


                        {/* Anniversary 175 section */}
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12 boxed boxed-home-page-form boxed--border boxed--shadow">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    <div className="boxed__pic-container col-12 col-md-6">
                                        <div className="boxed__pic__modal boxed__overview center-align-home-form">
                                            <EmailSender />
                                        </div>
                                    </div>
                                    <div className="boxed__text col-12 col-md-6 pb-3 pb-md-0 order-first">

                                        <img className="machine-sys" src={writerMachine512}
                                            srcSet={`${writerMachine768} 768w`}
                                            alt="A Kisfaludy fedélzete téged is vár!" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section brand-ref--section bg--white">
                    <div className="container">
                        <div className="brand-ref__wrapper">
                            <div className="row justify-content-center justify-content-lg-between align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="brand-ref__img-container">
                                        <ModalImage
                                            className="departures__img"
                                            small={sr_768_25}
                                            large={sr_768_25}
                                            alt="Sánta Róza kalózhajó"
                                        />


                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <div className="brand-ref__text-container main-card-padding">
                                        <h4>{t("SantaRoza_section.boxTitle")}</h4>
                                        <h1>{t("SantaRoza_section.subtitle")}</h1>
                                        <p>{t("SantaRoza_section.bodyText.p1")}</p>
                                        <div className="text-center">
                                            <a className="btn type--uppercase autowidth" rel="noreferrer" target="_blank" href="https://santaroza.hu/">{t("SantaRoza_section.bodyText.buttonText")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </Aux>)
    }
    executeScroll = () => this.myRef.current.scrollIntoView();


}


export default withTranslation()(HomePage);