//COMPONENTS
import React, { Component } from 'react';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import ReactDOM from 'react-dom';
//COMPONENTS END

//MYCOMPONENTS
import Aux from '../../components/Hook/Auxiliary'
import EmailSender from '../../components/Form/EventPageSenderForm/emailSender';
import PageFlip from '../../components/PageFlip/index.js'
import Gallery from '../../components/Gallery/index';


//MYCOMPONENTS END

//IMGS

//1.PHOTOS

import lemos from '../../img/koktelok_020.jpg';
//import lemos2 from '../../img/bar-page/koktelok_013.jpg';

import nextIcon from '../../img/bar-page/next-icon.svg'

import coctailIcon from '../../img/icons/icon-cocktail.svg';
import coffeeIcon from '../../img/icons/icon-coffee.svg';
import lemonadeIcon from '../../img/icons/icon-lemonade.svg';

//import lemo from '../../img/hero_slider/768/husito.jpg'
import lemo2 from '../../img/bar-page/koktelok_025.jpeg'

import Sticky from 'react-sticky-el';
import CheckerHook from '../../components/Hook/index'

import ExpireComp from '../../components/ExpireComponent/index'
class KisfaludyBar extends Component {



    constructor() {
        super();


        this.setActiveTab = this.setActiveTab.bind(this)

        this.state = {
            width: 0,
            height: 0,
            currentHeight: 0,
            pageYOffset: 0,
            stickyFooterActive: true,
            menuAppear: true,
            display:''
        }


    }


    setActiveTab(e) {
        console.log('klikk!');
        this.setState({
            menuAppear: false,
            display:'display-none-class'
        })
    }

    updateDimensions() {

        if (window.innerWidth > 750 && window.innerWidth < 1200) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 1.5);
            this.setState({ width: update_width, height: update_height, currentHeight: window.innerHidth });
        }
        else if (window.innerWidth > 1200 && window.innerWidth < 1300) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.2);
            this.setState({ width: update_width, height: update_height, currentHeight: window.innerHidth });

        }
        else if (window.innerWidth > 1300 && window.innerWidth < 1400) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.3);
            this.setState({ width: update_width, height: update_height, currentHeight: window.innerHidth });

        }
        else if (window.innerWidth > 1400 && window.innerWidth < 1500) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.4);
            this.setState({ width: update_width, height: update_height, currentHeight: window.innerHidth });

        }
        else if (window.innerWidth > 1500 && window.innerWidth < 1600) {
            let update_width = window.innerWidth;
            let update_height = Math.round(update_width / 2.5);
            this.setState({ width: update_width, height: update_height, currentHeight: window.innerHidth });

        }
        else if (window.innerWidth > 1600) {
            let update_width = window.innerWidth;
            let update_height = window.innerHeight;
            this.setState({ width: update_width, height: update_height, currentHeight: window.innerHidth });
        }
        else {
            let update_width = 100;
            let update_height = 100;
            this.setState({ width: update_width, height: update_height });

        }
        this.setState({ currentHeight: window.innerHeight });

    }


    schrollChecker() {
        // console.log("windows width:",window.innerWidth)
        //console.log("windows pageYoffset:",window.pageYOffset)
        //console.log("result: ",window.pageYOffset-window.innerWidth)
        let result = window.pageYOffset - window.innerWidth;
        if (result >= 2500 && result <= 3000 && window.innerWidth < 450) {


            this.setState({
                pageYOffset: window.pageYOffset
            });

            if (this.state.stickyFooterActive) {
                this.setState({
                    stickyFooterActive: false
                });
            }
        }

        else if (result >= 2000 && result <= 3000 && window.innerWidth > 450 && window.innerWidth < 768) {


            this.setState({
                pageYOffset: window.pageYOffset
            });

            if (this.state.stickyFooterActive) {
                this.setState({
                    stickyFooterActive: false
                });
            }
        }

        else if (result >= 1500 && result <= 2200 && window.innerWidth >= 768 && window.innerWidth < 992) {


            this.setState({
                pageYOffset: window.pageYOffset
            });

            if (this.state.stickyFooterActive) {
                this.setState({
                    stickyFooterActive: false
                });
            }
        }

        else if (result >= 800 && result <= 1800 && window.innerWidth >= 992 && window.innerWidth < 1200) {


            this.setState({
                pageYOffset: window.pageYOffset
            });

            if (this.state.stickyFooterActive) {
                this.setState({
                    stickyFooterActive: false
                });
            }
        }

        else if (result >= 400 && result <= 1500 && window.innerWidth >= 1200) {


            this.setState({
                pageYOffset: window.pageYOffset
            });

            if (this.state.stickyFooterActive) {
                this.setState({
                    stickyFooterActive: false
                });
            }
        }


        else {
            if (!this.state.stickyFooterActive) {
                this.setState({
                    stickyFooterActive: true
                });
            }
        }

    }



    componentDidMount() {
        this.updateDimensions();
        this.schrollChecker();
        window.addEventListener('scroll', this.schrollChecker.bind(this));
        window.addEventListener("resize", this.updateDimensions.bind(this));
        ReactDOM.findDOMNode(this).onclick = this.props.onClick

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.schrollChecker.bind(this));
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }
    render() {
        const { t } = this.props;
        let navBarClassInv = "wizard";
        let navBarClass = "";



        if (this.state.stickyFooterActive) {

        } else {
            navBarClass = " wizard"
            navBarClassInv = "";
        }


        return (
            <Aux>
                <div className={"menu-bar" + navBarClass}>
                    <Sticky hideOnBoundaryHit={true} className="nav--section" topOffset={150} bottomOffset={300}>
                        <CheckerHook />
                    </Sticky>
                </div>
                <div className={navBarClassInv}>

                    {/*  <Sticky hideOnBoundaryHit={true} className="nav--section" topOffset={150} bottomOffset={300}>
                        {this.state.stickyFooterActive ? (<div></div>) : (
                            <ExpireComp delay="5000">
                                <div className="kisfaludy-trick-sticky-navbra">
                                    <p>Lapozz az oldalak megtekintéséhez!</p>
                                </div>
                            </ExpireComp>)}
                        </Sticky>*/}

                </div>
                <section className='hero-section hero-section--bar' id="tortenelem-hero">

                    <span className="filter--black filter--black--gradient"></span>

                    <div className="hero-section__content">
                        <div className="container container-xl">
                            <div className="col-12 col-md-8 col-lg-6">
                                <div className="hero-section__text">
                                    <h1 className="hero-section__title"><strong>{t("BarPage.Hero_section.boxTitle")}</strong></h1>
                                    <hr className="short" />
                                    <div className="hero-section__bodytext">
                                        <p>{t("BarPage.Hero_section.bodyText")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="slide-container">
                        <Fade className="test" autoplay={'on'} duration={3000} autoplay={true} pauseOnHover={false} infinite={true}>
                            <div className="each-fade">
                                <div style={{'backgroundImage': `url(${imageUrl1})`}}>
                                <h3 style={col}>test1</h3>
                                </div>
                               
                            </div>
                            <div className="each-fade">
                            <div style={{'backgroundImage': `url(${imageUrl2})`}}>
                                <h3 style={col}>test2</h3>
                                </div>
                            </div>
                            <div className="each-fade">
                            <div style={{'backgroundImage': `url(${imageUrl3})`}}>
                                <h3 style={col}>test3</h3>
                                </div>
                              
                            </div>
                        </Fade>
        </div>*/}

                </section>


                <section className="section story--section py-5 py-lg-6">
                    <div className="container container-xl">

                        <div className="section-content__wrapper row align-items-stretch justify-content-center">
                            <div className="section-content__text col-12 col-lg-6 pr-lg-5">

                                <h1 className="section-content__title">{t("BarPage.Story_section.boxTitle")}</h1>
                                <hr className="short" />
                                <div className="section-content__bodytext">
                                    <p>
                                        {t("BarPage.Story_section.bodyText.p1")}
                                    </p>

                                    <p>
                                        {t("BarPage.Story_section.bodyText.p2")}
                                    </p>

                                    <p>
                                        {t("BarPage.Story_section.bodyText.p3")}
                                    </p>


                                </div>


                            </div>
                            <div className="section-content__pic story col-12 col-lg-6">
                                <img src={lemos}
                                    alt="frissítő-variáció" />
                            </div>
                        </div>

                    </div>

                </section>



                <section className="section thematic-trips--section story--section py-5 py-lg-6">
                    <div className="container container-xl">

                        <div className="section-content__wrapper row align-items-stretch justify-content-center">

                            <div className="section-content__text col-12 col-lg-6 pl-lg-5 order-1 order-lg-2">
                                <h1 className="section-content__title">{t("BarPage.Story_section2.boxTitle")}</h1>
                                <hr className="short" />
                                <div className="section-content__bodytext">


                                    <div className="boxed__text thematic-trips__item col-12 pl-0">
                                        <div className="temathic-content-wrapper temathic-content-wrapper-more">
                                  <img className="icons-class bar_pikto" alt="cocktailicon" src={lemonadeIcon} />
                                            <span className="boxed__title temathic-subtitle">
                                                {t('TimeTable_section.Temathic_group.fresh')}
                                            </span>
                                        </div>


                                        <div className="boxed__bodytext">
                                            <p>{t('BarPage.Story_section2.bodyText.p2')}</p>
                                        </div>
                                    </div>

                                    <div className="boxed__text thematic-trips__item col-12 pl-0">
                                        <div className="temathic-content-wrapper temathic-content-wrapper-more">
                                            <img className="icons-class bar_pikto" alt="cocktailicon" src={coffeeIcon} />
                                            <span className="boxed__title temathic-subtitle">
                                                {t('TimeTable_section.Temathic_group.cappucchino')}
                                            </span>
                                        </div>


                                        <div className="boxed__bodytext">
                                            <p>{t('BarPage.Story_section2.bodyText.p1')}</p>
                                        </div>
                                    </div>

                                    <div className="boxed__text thematic-trips__item col-12 pl-0">
                                        <div className="temathic-content-wrapper temathic-content-wrapper-more">
                                            <img className="icons-class bar_pikto" alt="cocktailicon" src={coctailIcon} />
                                            <span className="boxed__title temathic-subtitle">
                                                {t('TimeTable_section.Temathic_group.coctail')}
                                            </span>
                                        </div>


                                        <div className="boxed__bodytext">
                                            <p>{t('BarPage.Story_section2.bodyText.p3')}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="section-content__pic rebuild col-12 col-lg-6 order-1">

                                <img src={lemo2}
                                    alt=" A hajó felavatása pezsgővel" />
                            </div>

                        </div>

                    </div>

                </section>




                <section id="custom-flip-book" className="container container-xl">
                    <h1 className="section-content__title">{t('BarPage.Story_section2.bodyText.menu_title')}</h1>
                    <hr className="short" />

                    {this.state.menuAppear ? (
                        <ExpireComp clickEvent={this.state.menuAppear} delay="5000">
                            {/*    <div className="sch-wrapper">
                                <div>
                                    <div className="container-layer">
                                        <div className="content-wrapper-layer">

                                            <p className="text-typo-helper">
                                                KATTINTS A LAPOZÁSHOZ
                                            </p>
                                            <img className="next-icon" src={nextIcon}></img>
                                        </div>
                                    </div>

                                </div>
                               <a className="sch-layer" style={{height:this.state.currentHeight}} onClick={this.setActiveTab}>


                    </a>
                            </div>*/}
                        </ExpireComp>) : (<div></div>)}




                   
                            <div className={"sch-wrapper "+this.state.display}>
                                <span className="helper-wrapper">
                                    <p className="text-typo-helper">
                                        KATTINTS A LAPOZÁSHOZ
                                    </p>
                                    <img className="next-icon" alt="next" src={nextIcon}></img>
                                </span>



                            </div>
<Link onClick={this.setActiveTab} className={"firstLayer "+this.state.display}></Link>
   
                            <PageFlip menuWidth={this.state.width} menuHeight={this.state.height} />
                  



                </section>

                <Suspense fallback={<h1>Loading</h1>}>
                    <Gallery name="Kisfaludybar" />
                </Suspense>




                <section className="section contact-us--section bg--secondary">



                    <div className="container container-xl">
                        <div className="row justify-content-between ">


                            <div className="col-12 col-lg-6">
                                <div className="text-container section__text-block">

                                    <h3 className="text-block__title">
                                        {t("EventsPage.Events_section.bodyText.form_section.boxTitle")}
                                    </h3>
                                    <hr className="short " />
                                    <p>
                                        {t("EventsPage.Events_section.bodyText.form_section.introText")}
                                    </p>
                                    <ul>
                                        <li><b>{t("Map_section.subtitle")}  </b>{t("Map_section.subtitleText")}</li>

                                    </ul>


                                </div>
                            </div>

                            <div className="col-12 col-lg-6">

                                <div className="form-container boxed boxed--compact boxed--border border--light boxed--shadow">
                                    <Suspense fallback={<h1>Loading</h1>}>
                                        <EmailSender />
                                    </Suspense>
                                </div>

                            </div>

                        </div>


                    </div>


                </section>

            </Aux>
        )
    }


}

export default withTranslation()(KisfaludyBar);