import React from 'react';
import augusztus20_tuzijatek_brosura from '../../files/augusztus20_tuzijatek_brosura.png'
import { BlobProvider } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },


});
// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4">
      <View >
        <Text>Section #1</Text>
      </View>
      <View >
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
);



function MyDocumentW() {
  return (
    <BlobProvider document={<MyDocument/>}>
    {({ blob, url, loading }) => {
      return loading ? <div>Loading..</div> : (
        <Document file={url}
                            onLoadSuccess={(pdf) => this.handleLoadSuccess(pdf, blob)}
                            renderMode="canvas">
              <Page pageNumber={1}
                         width={window.innerWidth}/>
        </Document>
      );
    }}
</BlobProvider>
  );
}

export default MyDocumentW;