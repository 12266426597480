//COMPONENTS START
import React from 'react'
import Flickity from 'react-flickity-component';
//COMPONENTS END

//IMGS START
import kisfaludy_480_100 from '../../img/480/kisfaludy-480-100.jpg';
import kisfaludy_512_100 from '../../img/512/kisfaludy-512-100.jpg';
import kisfaludy_768_100 from '../../img/768/kisfaludy-768-100.jpg';

import kisfaludy_480_101 from '../../img/480/kisfaludy-480-101.jpg';
import kisfaludy_512_101 from '../../img/512/kisfaludy-512-101.jpg';
import kisfaludy_768_101 from '../../img/768/kisfaludy-768-101.jpg';

import kisfaludy_480_102 from '../../img/480/kisfaludy-480-102.jpg';
import kisfaludy_512_102 from '../../img/512/kisfaludy-512-102.jpg';
import kisfaludy_768_102 from '../../img/768/kisfaludy-768-102.jpg';

import kisfaludy_480_103 from '../../img/480/kisfaludy-480-103.jpg';
import kisfaludy_512_103 from '../../img/512/kisfaludy-512-103.jpg';
import kisfaludy_768_103 from '../../img/768/kisfaludy-768-103.jpg';

import kisfaludy_480_06 from '../../img/480/kisfaludy-480-06.jpg';
import kisfaludy_512_06 from '../../img/512/kisfaludy-512-06.jpg';
import kisfaludy_768_06 from '../../img/768/kisfaludy-768-06.jpg';


import kisfaludy_480_21 from '../../img/480/kisfaludy-480-21.jpg';
import kisfaludy_512_21 from '../../img/512/kisfaludy-512-21.jpg';
import kisfaludy_768_21 from '../../img/768/kisfaludy-768-21.jpg';


import kisfaludy_480_22 from '../../img/480/kisfaludy-480-22.jpg';
import kisfaludy_512_22 from '../../img/512/kisfaludy-512-22.jpg';
import kisfaludy_768_22 from '../../img/768/kisfaludy-768-22.jpg';


import kisfaludy_480_11 from '../../img/480/kisfaludy-480-11.jpg';
import kisfaludy_512_11 from '../../img/512/kisfaludy-512-11.jpg';
import kisfaludy_768_11 from '../../img/768/kisfaludy-768-11.jpg';

import kisfaludy_480_13 from '../../img/480/kisfaludy-480-13.jpg';
import kisfaludy_512_13 from '../../img/512/kisfaludy-512-13.jpg';
import kisfaludy_768_13 from '../../img/768/kisfaludy-768-13.jpg';


import kisfaludy_480_19 from '../../img/480/kisfaludy-480-19.jpg';
import kisfaludy_512_19 from '../../img/512/kisfaludy-512-19.jpg';
import kisfaludy_768_19 from '../../img/768/kisfaludy-768-19.jpg';


import kisfaludy_480_10 from '../../img/480/kisfaludy-480-10.jpg';
import kisfaludy_512_10 from '../../img/512/kisfaludy-512-10.jpg';
import kisfaludy_768_10 from '../../img/768/kisfaludy-768-10.jpg';


import kisfaludy_480_07 from '../../img/480/kisfaludy-480-07.jpg';
import kisfaludy_512_07 from '../../img/512/kisfaludy-512-07.jpg';
import kisfaludy_768_07 from '../../img/768/kisfaludy-768-07.jpg';


import kisfaludy_480_16 from '../../img/480/kisfaludy-480-16.jpg';
import kisfaludy_512_16 from '../../img/512/kisfaludy-512-16.jpg';
import kisfaludy_768_16 from '../../img/768/kisfaludy-768-16.jpg';


import kisfaludy_480_12 from '../../img/480/kisfaludy-480-12.jpg';
import kisfaludy_512_12 from '../../img/512/kisfaludy-512-12.jpg';
import kisfaludy_768_12 from '../../img/768/kisfaludy-768-12.jpg';



import kisfaludy_480_17 from '../../img/480/kisfaludy-480-17.jpg';
import kisfaludy_512_17 from '../../img/512/kisfaludy-512-17.jpg';
import kisfaludy_768_17 from '../../img/768/kisfaludy-768-17.jpg';


import kisfaludy_480_20 from '../../img/480/kisfaludy-480-20.jpg';
import kisfaludy_512_20 from '../../img/512/kisfaludy-512-20.jpg';
import kisfaludy_768_20 from '../../img/768/kisfaludy-768-20.jpg';


import kisfaludy_480_23 from '../../img/480/kisfaludy-480-23.jpg';
import kisfaludy_512_23 from '../../img/512/kisfaludy-512-23.jpg';
import kisfaludy_768_23 from '../../img/768/kisfaludy-768-23.jpg';


import kisfaludy_480_01 from '../../img/480/kisfaludy-480-01.jpg';
import kisfaludy_512_01 from '../../img/512/kisfaludy-512-01.jpg';
import kisfaludy_768_01 from '../../img/768/kisfaludy-768-01.jpg';


import kisfaludy_480_24 from '../../img/480/kisfaludy-480-24.jpg';
import kisfaludy_512_24 from '../../img/512/kisfaludy-512-24.jpg';
import kisfaludy_768_24 from '../../img/768/kisfaludy-768-24.jpg';


import kisfaludy_480_26 from '../../img/480/kisfaludy-480-26.jpg';
import kisfaludy_512_26 from '../../img/512/kisfaludy-512-26.jpg';
import kisfaludy_768_26 from '../../img/768/kisfaludy-768-26.jpg';


import kisfaludy_480_25 from '../../img/480/kisfaludy-480-25.jpg';
import kisfaludy_512_25 from '../../img/512/kisfaludy-512-25.jpg';
import kisfaludy_768_25 from '../../img/768/kisfaludy-768-25.jpg';


import kisfaludy_480_04 from '../../img/480/kisfaludy-480-04.jpg';
import kisfaludy_512_04 from '../../img/512/kisfaludy-512-04.jpg';
import kisfaludy_768_04 from '../../img/768/kisfaludy-768-04.jpg';


import kisfaludy_480_14 from '../../img/480/kisfaludy-480-14.jpg';
import kisfaludy_512_14 from '../../img/512/kisfaludy-512-14.jpg';
import kisfaludy_768_14 from '../../img/768/kisfaludy-768-14.jpg';


import kisfaludy_480_eskuvo1 from '../../img/480/kisfaludy-eskuvo07.jpg';
import kisfaludy_512_eskuvo1 from '../../img/512/kisfaludy-eskuvo07.jpg';
import kisfaludy_768_eskuvo1 from '../../img/768/kisfaludy-eskuvo07.jpg';


import kisfaludy_480_eskuvo2 from '../../img/480/kisfaludy-eskuvo09.jpg';
import kisfaludy_512_eskuvo2 from '../../img/512/kisfaludy-eskuvo09.jpg';
import kisfaludy_768_eskuvo2 from '../../img/768/kisfaludy-eskuvo09.jpg';


import kisfaludy_480_eskuvo3 from '../../img/480/kisfaludy-eskuvo05.jpg';
import kisfaludy_512_eskuvo3 from '../../img/512/kisfaludy-eskuvo05.jpg';
import kisfaludy_768_eskuvo3 from '../../img/768/kisfaludy-eskuvo05.jpg';


import kisfaludy_480_eskuvo4 from '../../img/480/kisfaludy-eskuvo11.jpg';
import kisfaludy_512_eskuvo4 from '../../img/512/kisfaludy-eskuvo11.jpg';
import kisfaludy_768_eskuvo4 from '../../img/768/kisfaludy-eskuvo11.jpg';


import kisfaludy_480_eskuvo5 from '../../img/480/kisfaludy-eskuvo02.jpg';
import kisfaludy_512_eskuvo5 from '../../img/512/kisfaludy-eskuvo02.jpg';
import kisfaludy_768_eskuvo5 from '../../img/768/kisfaludy-eskuvo02.jpg';


import kisfaludy_480_eskuvo6 from '../../img/480/kisfaludy-eskuvo06.jpg';
import kisfaludy_512_eskuvo6 from '../../img/512/kisfaludy-eskuvo06.jpg';
import kisfaludy_768_eskuvo6 from '../../img/768/kisfaludy-eskuvo06.jpg';


import kisfaludy_480_eskuvo7 from '../../img/480/kisfaludy-eskuvo01.jpg';
import kisfaludy_512_eskuvo7 from '../../img/512/kisfaludy-eskuvo01.jpg';
import kisfaludy_768_eskuvo7 from '../../img/768/kisfaludy-eskuvo01.jpg';


import kisfaludy_480_eskuvo8 from '../../img/480/kisfaludy-eskuvo04.jpg';
import kisfaludy_512_eskuvo8 from '../../img/512/kisfaludy-eskuvo04.jpg';
import kisfaludy_768_eskuvo8 from '../../img/768/kisfaludy-eskuvo04.jpg';


import kisfaludy_480_corp1 from '../../img/480/kisfaludy-ceges-15.jpg';
import kisfaludy_512_corp1 from '../../img/512/kisfaludy-ceges-15.jpg';
import kisfaludy_768_corp1 from '../../img/768/kisfaludy-ceges-15.jpg';


import kisfaludy_480_corp2 from '../../img/480/kisfaludy-480-52.jpg';
import kisfaludy_512_corp2 from '../../img/512/kisfaludy-512-52.jpg';
import kisfaludy_768_corp2 from '../../img/768/kisfaludy-768-52.jpg';


import kisfaludy_480_corp3 from '../../img/480/kisfaludy-csaladi-08.jpg';
import kisfaludy_512_corp3 from '../../img/512/kisfaludy-csaladi-08.jpg';
import kisfaludy_768_corp3 from '../../img/768/kisfaludy-csaladi-08.jpg';


import kisfaludy_480_corp4 from '../../img/480/kisfaludy-ceges-11.jpg';
import kisfaludy_512_corp4 from '../../img/512/kisfaludy-ceges-11.jpg';
import kisfaludy_768_corp4 from '../../img/768/kisfaludy-ceges-11.jpg';


import kisfaludy_480_corp5 from '../../img/480/kisfaludy-ceges-12.jpg';
import kisfaludy_512_corp5 from '../../img/512/kisfaludy-ceges-12.jpg';
import kisfaludy_768_corp5 from '../../img/768/kisfaludy-ceges-12.jpg';


import kisfaludy_480_corp6 from '../../img/480/kisfaludy-ceges-13.jpg';
import kisfaludy_512_corp6 from '../../img/512/kisfaludy-ceges-13.jpg';
import kisfaludy_768_corp6 from '../../img/768/kisfaludy-ceges-13.jpg';


import kisfaludy_480_corp7 from '../../img/480/kisfaludy-ceges-14.jpg';
import kisfaludy_512_corp7 from '../../img/512/kisfaludy-ceges-14.jpg';
import kisfaludy_768_corp7 from '../../img/768/kisfaludy-ceges-14.jpg';


import kisfaludy_480_family1 from '../../img/480/kisfaludy-csaladi-13.jpg';
import kisfaludy_512_family1 from '../../img/512/kisfaludy-csaladi-13.jpg';
import kisfaludy_768_family1 from '../../img/768/kisfaludy-csaladi-13.jpg';


import kisfaludy_480_family2 from '../../img/480/kisfaludy-csaladi-03.jpg';
import kisfaludy_512_family2 from '../../img/512/kisfaludy-csaladi-03.jpg';
import kisfaludy_768_family2 from '../../img/768/kisfaludy-csaladi-03.jpg';


import kisfaludy_480_family3 from '../../img/480/kisfaludy-csaladi-04.jpg';
import kisfaludy_512_family3 from '../../img/512/kisfaludy-csaladi-04.jpg';
import kisfaludy_768_family3 from '../../img/768/kisfaludy-csaladi-04.jpg';


import kisfaludy_480_family4 from '../../img/480/kisfaludy-csaladi-05.jpg';
import kisfaludy_512_family4 from '../../img/512/kisfaludy-csaladi-05.jpg';
import kisfaludy_768_family4 from '../../img/768/kisfaludy-csaladi-05.jpg';


import kisfaludy_480_family5 from '../../img/480/kisfaludy-480-40.jpg';
import kisfaludy_512_family5 from '../../img/512/kisfaludy-512-40.jpg';
import kisfaludy_768_family5 from '../../img/768/kisfaludy-768-40.jpg';


import kisfaludy_480_family6 from '../../img/480/kisfaludy-csaladi-12.jpg';
import kisfaludy_512_family6 from '../../img/512/kisfaludy-csaladi-12.jpg';
import kisfaludy_768_family6 from '../../img/768/kisfaludy-csaladi-12.jpg';


import kisfaludy_480_shooting1 from '../../img/480/kisfaludy-shooting-6.jpg';
import kisfaludy_512_shooting1 from '../../img/512/kisfaludy-shooting-6.jpg';
import kisfaludy_768_shooting1 from '../../img/768/kisfaludy-shooting-6.jpg';


import kisfaludy_480_shooting2 from '../../img/480/kisfaludy-shooting-2.jpg';
import kisfaludy_512_shooting2 from '../../img/512/kisfaludy-shooting-2.jpg';
import kisfaludy_768_shooting2 from '../../img/768/kisfaludy-shooting-2.jpg';


import kisfaludy_480_shooting3 from '../../img/480/kisfaludy-shooting-3.jpg';
import kisfaludy_512_shooting3 from '../../img/512/kisfaludy-shooting-3.jpg';
import kisfaludy_768_shooting3 from '../../img/768/kisfaludy-shooting-3.jpg';


import kisfaludy_480_shooting4 from '../../img/480/kisfaludy-shooting-4.jpg';
import kisfaludy_512_shooting4 from '../../img/512/kisfaludy-shooting-4.jpg';
import kisfaludy_768_shooting4 from '../../img/768/kisfaludy-shooting-4.jpg';


import kisfaludy_480_shooting5 from '../../img/480/kisfaludy-shooting-5.jpg';
import kisfaludy_512_shooting5 from '../../img/512/kisfaludy-shooting-5.jpg';
import kisfaludy_768_shooting5 from '../../img/768/kisfaludy-shooting-5.jpg';


//KISFALDUYBAR


import kisfaludy_300_kisfaludybar2 from '../../img/hero_slider/300/kisfaludy_2.jpg';
import kisfaludy_512_kisfaludybar2 from '../../img/hero_slider/512/kisfaludy_2.jpg';
import kisfaludy_768_kisfaludybar2 from '../../img/hero_slider/768/kisfaludy_2.jpg';

import kisfaludy_480_kisfaludybar3 from '../../img/hero_slider/480/kisfaludy_3.jpg';
import kisfaludy_512_kisfaludybar3 from '../../img/hero_slider/512/kisfaludy_3.jpg';
import kisfaludy_768_kisfaludybar3 from '../../img/hero_slider/768/kisfaludy_3.jpg';

import kisfaludy_480_kisfaludybar4 from '../../img/hero_slider/480/kisfaludy_4.jpg';
import kisfaludy_512_kisfaludybar4 from '../../img/hero_slider/512/kisfaludy_4.jpg';
import kisfaludy_768_kisfaludybar4 from '../../img/hero_slider/768/kisfaludy_4.jpg';

import kisfaludy_480_kisfaludybar5 from '../../img/hero_slider/480/kisfaludy_5.jpg';
import kisfaludy_512_kisfaludybar5 from '../../img/hero_slider/512/kisfaludy_5.jpg';
import kisfaludy_768_kisfaludybar5 from '../../img/hero_slider/768/kisfaludy_5.jpg';

import kisfaludy_300_kisfaludybar6 from '../../img/hero_slider/300/kisfaludy_6.jpg';
import kisfaludy_512_kisfaludybar6 from '../../img/hero_slider/512/kisfaludy_6.jpg';
import kisfaludy_768_kisfaludybar6 from '../../img/hero_slider/768/kisfaludy_6.jpg';


import kisfaludy_768_kisfaludybar8 from '../../img/koktelok_024.jpg';

import kisfaludy_480_kisfaludybar002 from '../../img/hero_slider/480/koktelok_002.jpg';
import kisfaludy_512_kisfaludybar002 from '../../img/hero_slider/512/koktelok_002.jpg';
import kisfaludy_768_kisfaludybar002 from '../../img/hero_slider/768/koktelok_002.jpg';

import kisfaludy_480_kisfaludybar030 from '../../img/hero_slider/480/koktelok_030.jpg';
import kisfaludy_512_kisfaludybar030 from '../../img/hero_slider/512/koktelok_030.jpg';
import kisfaludy_768_kisfaludybar030 from '../../img/hero_slider/768/koktelok_030.jpg';

import kisfaludy_480_kisfaludybar044 from '../../img/hero_slider/480/koktelok_044.jpg';
import kisfaludy_512_kisfaludybar044 from '../../img/hero_slider/512/koktelok_044.jpg';
import kisfaludy_768_kisfaludybar044 from '../../img/hero_slider/768/koktelok_044.jpg';
//IMGS END

var data = {
    WeddingGallery: [
        {
            src: kisfaludy_480_eskuvo1,
            srcset: `${kisfaludy_512_eskuvo1} 512w, ${kisfaludy_768_eskuvo1} 768w`,
            alt: "várakozó násznép a szertartás előtt"
        },
        {
            src: kisfaludy_480_eskuvo2,
            srcset: `${kisfaludy_512_eskuvo2} 512w, ${kisfaludy_768_eskuvo2} 768w`,
            alt: "Esküvői hajó a Tihanyi Apátság lábánál"
        },
        {
            src: kisfaludy_480_eskuvo3,
            srcset: `${kisfaludy_512_eskuvo3} 512w, ${kisfaludy_768_eskuvo3} 768w`,
            alt: "Vendégváró falatok"
        },
        {
            src: kisfaludy_480_eskuvo4,
            srcset: `${kisfaludy_512_eskuvo4} 512w, ${kisfaludy_768_eskuvo4} 768w`,
            alt: "A Sánta Róza az esküvői járatról"
        },
        {
            src: kisfaludy_480_eskuvo5,
            srcset: `${kisfaludy_512_eskuvo5} 512w, ${kisfaludy_768_eskuvo5} 768w`,
            alt: "Az ifjú pár a nagy napon"
        },
        {
            src: kisfaludy_480_eskuvo6,
            srcset: `${kisfaludy_512_eskuvo6} 512w, ${kisfaludy_768_eskuvo6} 768w`,
            alt: "Ünnepi pezsgőbontás a fedélzeten"
        },
        {
            src: kisfaludy_480_eskuvo7,
            srcset: `${kisfaludy_512_eskuvo7} 512w, ${kisfaludy_768_eskuvo7} 768w`,
            alt: "A balatoni panoráma a hajó fedélzetéről"
        },
        {
            src: kisfaludy_480_eskuvo8,
            srcset: `${kisfaludy_512_eskuvo8} 512w, ${kisfaludy_768_eskuvo8} 768w`,
            alt: "Esküvői díszítés és hangulat"
        }

    ],

    corporate: [
        {
            src: kisfaludy_480_corp1,
            srcset: `${kisfaludy_512_corp1} 512w, ${kisfaludy_768_corp1} 768w`,
            alt: "Céges ünneplés a közös sikerekre"
        },
        {
            src: kisfaludy_480_corp2,
            srcset: `${kisfaludy_512_corp2} 512w, ${kisfaludy_768_corp2} 768w`,
            alt: "Pezsgős partik, rendezvények"
        },
        {
            src: kisfaludy_480_corp3,
            srcset: `${kisfaludy_512_corp3} 512w, ${kisfaludy_768_corp3} 768w`,
            alt: "Vendégváró falatok"
        },
        {
            src: kisfaludy_480_corp4,
            srcset: `${kisfaludy_512_corp4} 512w, ${kisfaludy_768_corp4} 768w`,
            alt: "Céges sikerek méltó megünneplése"
        },
        {
            src: kisfaludy_480_corp5,
            srcset: `${kisfaludy_512_corp5} 512w, ${kisfaludy_768_corp5} 768w`,
            alt: "Változatos falatkák az ünnepi találkozókhoz, évértékelőkhöz"
        },
        {
            src: kisfaludy_480_corp6,
            srcset: `${kisfaludy_512_corp6} 512w, ${kisfaludy_768_corp6} 768w`,
            alt: "Frissítők felszolgálása a megbeszélés kávészünetében"
        },
        {
            src: kisfaludy_480_corp7,
            srcset: `${kisfaludy_512_corp7} 512w, ${kisfaludy_768_corp7} 768w`,
            alt: "Különleges ízvilágú limonádék a nyári megbeszélésekhez"
        }

    ],

    family: [
        {
            src: kisfaludy_480_family1,
            srcset: `${kisfaludy_512_family1} 512w, ${kisfaludy_768_family1} 768w`,
            alt: "Izgalmas és egyedi italvariációk az alkalomhoz illő elképzelések tükrében"
        },
        {
            src: kisfaludy_480_family2,
            srcset: `${kisfaludy_512_family2} 512w, ${kisfaludy_768_family2} 768w`,
            alt: "Közös koccintás a családi eseményekre"
        },
        {
            src: kisfaludy_480_family3,
            srcset: `${kisfaludy_512_family3} 512w, ${kisfaludy_768_family3} 768w`,
            alt: "Dj-vel a fedélzeti bulik is felpörögnek"
        },
        {
            src: kisfaludy_480_family4,
            srcset: `${kisfaludy_512_family4} 512w, ${kisfaludy_768_family4} 768w`,
            alt: "Születésnapi rendezvények sétahajózással egybekötve"
        },
        {
            src: kisfaludy_480_family5,
            srcset: `${kisfaludy_512_family5} 512w, ${kisfaludy_768_family5} 768w`,
            alt: "Esküvői fogadás a Kisfaludy fedélzetén az esti holdfényben"
        },
        {
            src: kisfaludy_480_family6,
            srcset: `${kisfaludy_512_family6} 512w, ${kisfaludy_768_family6} 768w`,
            alt: "Állófogadások, canapék ünnepi alkalmakra"
        },
    ],

    shooting: [
        {
            src: kisfaludy_480_shooting1,
            srcset: `${kisfaludy_512_shooting1} 512w, ${kisfaludy_768_shooting1} 768w`,
            alt: "Fotó- és forgatási nap a Kisfaludyn"
        },
        {
            src: kisfaludy_480_shooting2,
            srcset: `${kisfaludy_512_shooting2} 512w, ${kisfaludy_768_shooting2} 768w`,
            alt: "Pörög a felvétel a kikötőben"
        },
        {
            src: kisfaludy_480_shooting3,
            srcset: `${kisfaludy_512_shooting3} 512w, ${kisfaludy_768_shooting3} 768w`,
            alt: "Egyedi fotósorozat készül a Kisfaludy fedélzetén"
        },
        {
            src: kisfaludy_480_shooting4,
            srcset: `${kisfaludy_512_shooting4} 512w, ${kisfaludy_768_shooting4} 768w`,
            alt: "Forgatási szünet a patinás térben"
        },
        {
            src: kisfaludy_480_shooting5,
            srcset: `${kisfaludy_512_shooting5} 512w, ${kisfaludy_768_shooting5} 768w`,
            alt: "Reklámfotók készülnek a Balatonnal a hajó hátterében"
        },
    ],

    homePageGallery: [      
        {
            src: kisfaludy_480_101,
            srcset: `${kisfaludy_512_101} 512w, ${kisfaludy_768_101} 768w`,
            alt: "Pohár bor a ksifaludygőzös fedélzetén"
        },
        {
            src: kisfaludy_480_102,
            srcset: `${kisfaludy_512_102} 512w, ${kisfaludy_768_102} 768w`,
            alt: "Házi sörök"
        },
        {
            src: kisfaludy_480_103,
            srcset: `${kisfaludy_512_103} 512w, ${kisfaludy_768_103} 768w`,
            alt: "Kávé variáció"
        },
        {
            src: kisfaludy_480_06,
            srcset: `${kisfaludy_512_06} 512w, ${kisfaludy_768_06} 768w`,
            alt: "Kisfaludy sétahajó a balatonfüredi kikötőben"
        },
        {
            src: kisfaludy_480_21,
            srcset: `${kisfaludy_512_21} 512w, ${kisfaludy_768_21} 768w`,
            alt: "A Kisfaludy 2020-ra készült harangja a fedélzeten"
        },
        {
            src: kisfaludy_480_22,
            srcset: `${kisfaludy_512_22} 512w, ${kisfaludy_768_22} 768w`,
            alt: "A Kisfaludy kapitánya a Tihanyi-félszigettel a háttérben"
        },
        {
            src: kisfaludy_480_11,
            srcset: `${kisfaludy_512_11} 512w, ${kisfaludy_768_11} 768w`,
            alt: "Több generáció a Kisfaludy fedélzetén"
        },
        {
            src: kisfaludy_480_100,
            srcset: `${kisfaludy_512_100} 512w, ${kisfaludy_768_100} 768w`,
            alt: "Tihanyi levendulás"
        },
        {
            src: kisfaludy_480_13,
            srcset: `${kisfaludy_512_13} 512w, ${kisfaludy_768_13} 768w`,
            alt: "A Kisfaludy szalontermében berendezett reformkori kiállítás"
        },
        {
            src: kisfaludy_480_19,
            srcset: `${kisfaludy_512_19} 512w, ${kisfaludy_768_19} 768w`,
            alt: "Kisfaludy sétahajó kötélzete"
        },
        {
            src: kisfaludy_480_10,
            srcset: `${kisfaludy_512_10} 512w, ${kisfaludy_768_10} 768w`,
            alt: "Családi kép készül a Kisfaludy és a Tihanyi-félsziget előterében"
        },
        {
            src: kisfaludy_480_07,
            srcset: `${kisfaludy_512_07} 512w, ${kisfaludy_768_07} 768w`,
            alt: "Családi kép készül a Kisfaludy és a Tihanyi-félsziget előterében"
        },
        {
            src: kisfaludy_480_16,
            srcset: `${kisfaludy_512_16} 512w, ${kisfaludy_768_16} 768w`,
            alt: "A Kisfaludy fedélzetéről a Tihanyi-félsziget tárul elénk"
        },
        {
            src: kisfaludy_480_12,
            srcset: `${kisfaludy_512_12} 512w, ${kisfaludy_768_12} 768w`,
            alt: "A legkisebbek is elkalandoznak a Kisfaludy fedélzetén"
        },
       
        {
            src: kisfaludy_480_17,
            srcset: `${kisfaludy_512_17} 512w, ${kisfaludy_768_17} 768w`,
            alt: "A balatonfüredi mólón várja utasait a Kisfaludy lapátkerekes gőzhajó"
        },
        {
            src: kisfaludy_480_20,
            srcset: `${kisfaludy_512_20} 512w, ${kisfaludy_768_20} 768w`,
            alt: "A reformkori hajózási kiállításon korhű jegyzetek, iratok is fellelhetőek"
        },
        {
            src: kisfaludy_480_23,
            srcset: `${kisfaludy_512_23} 512w, ${kisfaludy_768_23} 768w`,
            alt: "A lapátkerék működésse megtekinthető a hajó fedélzetéről"
        },
        {
            src: kisfaludy_480_01,
            srcset: `${kisfaludy_512_01} 512w, ${kisfaludy_768_01} 768w`,
            alt: "Naplementés visszaút Balatonfüred irányába"
        },
        {
            src: kisfaludy_480_24,
            srcset: `${kisfaludy_512_24} 512w, ${kisfaludy_768_24} 768w`,
            alt: "Számos érdekesség olvasható a reformkorról és a hajóról a szalontérben berendezett múzeumunkban"
        },
        {
            src: kisfaludy_480_26,
            srcset: `${kisfaludy_512_26} 512w, ${kisfaludy_768_26} 768w`,
            alt: "A Kisfaludy árbóca és kötélzete"
        },
        {
            src: kisfaludy_480_25,
            srcset: `${kisfaludy_512_25} 512w, ${kisfaludy_768_25} 768w`,
            alt: "A mólón futkozó gyerkőcöket is megfogja a sok hajó körüli teendő"
        },
        {
            src: kisfaludy_480_04,
            srcset: `${kisfaludy_512_04} 512w, ${kisfaludy_768_04} 768w`,
            alt: "Lapátkerekes gőzösünk a Tihanyi-félsziget lábánál"
        },
        {
            src: kisfaludy_480_14,
            srcset: `${kisfaludy_512_14} 512w, ${kisfaludy_768_14} 768w`,
            alt: "A reformkort idéző hajókürtök is megtalálhatók a Kisfaludy fedélzetén"
        },
    ],
    kisfaludyBar: [

      
      
        {
            src: kisfaludy_480_kisfaludybar3,
            srcset: `${kisfaludy_512_kisfaludybar3} 512w, ${kisfaludy_768_kisfaludybar3} 768w`,
            alt: "Jeges tea"
        },
        {
            src: kisfaludy_768_kisfaludybar8,
            srcset: `${kisfaludy_768_kisfaludybar8} 768w`,
            alt: "Jeges teáink"
        },
      
    
        {
            src: kisfaludy_300_kisfaludybar2,
            srcset: `${kisfaludy_512_kisfaludybar2} 480w,${kisfaludy_512_kisfaludybar2} 512w, ${kisfaludy_768_kisfaludybar2} 768w`,
            alt: "Frissítő válogatás"
        },
        {
            src: kisfaludy_480_kisfaludybar044,
            srcset: `${kisfaludy_512_kisfaludybar044} 512w,${kisfaludy_768_kisfaludybar044} 768w`,
            alt: "Ital variáció1"
        },
        {
            src: kisfaludy_480_kisfaludybar002,
            srcset: `${kisfaludy_512_kisfaludybar002} 512w,${kisfaludy_768_kisfaludybar002} 768w`,
            alt: "Ital variáció3"
        },
        {
            src: kisfaludy_480_kisfaludybar4,
            srcset: `${kisfaludy_512_kisfaludybar4} 512w, ${kisfaludy_768_kisfaludybar4} 768w`,
            alt: "Tihanyi levendulás"
        },
        {
            src: kisfaludy_300_kisfaludybar6,
            srcset: `${kisfaludy_512_kisfaludybar6} 512w,${kisfaludy_768_kisfaludybar6} 768w`,
            alt: "Cappucchino"
        }, 
        {
            src: kisfaludy_480_kisfaludybar5,
            srcset: `${kisfaludy_512_kisfaludybar5} 512w, ${kisfaludy_768_kisfaludybar5} 768w`,
            alt: "Tihanyi levendulás"
        },
        {
            src: kisfaludy_480_kisfaludybar030,
            srcset: `${kisfaludy_512_kisfaludybar030} 512w,${kisfaludy_768_kisfaludybar030} 768w`,
            alt: "Ital variáció2"
        },
        
    ]
};


const flickityOptions2 = {
    initialIndex: 2,
    wrapAround: true,
    pageDots: false,
    lazyLoad: true,
    autoPlay: true,
}

function Gallery(props) {
    let flickityList = [];


    if (props.name === 'Homepage') {
        flickityList = data.homePageGallery.map((image, index) => (
            <div className={'slide__' + index} key={index}>
                <div className="img-container" key={index}>
                    <img src={image.src} alt={image.alt} srcSet={image.srcset} key={index} />
                </div>
            </div>))
    }
    else if (props.name === 'Wedding') {
        flickityList = data.WeddingGallery.map((image, index) => (
            <div className={'slide__' + index} key={index}>
                <div className="img-container" key={index}>
                    <img src={image.src} alt={image.alt} srcSet={image.srcset} key={index} />
                </div>
            </div>))
    }
    else if (props.name === 'Corp') {
        flickityList = data.corporate.map((image, index) => (
            <div className={'slide__' + index} key={index}>
                <div className="img-container" key={index}>
                    <img src={image.src} alt={image.alt} srcSet={image.srcset} key={index} />
                </div>
            </div>))
    }
    else if (props.name === 'Family') {
        flickityList = data.family.map((image, index) => (
            <div className={'slide__' + index} key={index}>
                <div className="img-container" key={index}>
                    <img src={image.src} alt={image.alt} srcSet={image.srcset} key={index} />
                </div>
            </div>))
    }
    else if (props.name === 'Photo') {
        flickityList = data.shooting.map((image, index) => (
            <div className={'slide__' + index} key={index}>
                <div className="img-container" key={index}>
                    <img src={image.src} alt={image.alt} srcSet={image.srcset} key={index} />
                </div>
            </div>))
    }
    else if(props.name === 'Kisfaludybar'){
        flickityList = data.kisfaludyBar.map((image, index) => (
            <div className={'slide__' + index} key={index}>
                <div className="img-container" key={index}>
                    <img src={image.src} alt={image.alt} srcSet={image.srcset} key={index} />
                </div>
            </div>))
    }

return (<section className="section gallery--section">
    <div className="container-fluid p-0 m-0">

        <div className="image-slider col-12">
            <Flickity
                className={'carousel flickity-viewport'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions2} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
            >
                {

                    flickityList
                }
            </Flickity>
        </div>


    </div>
</section>
)
}
export default Gallery;