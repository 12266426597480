//COMPONENTS START
import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { withTranslation } from 'react-i18next';
//COMPONENTS END

//IMGS START
import kisfaludy_logo_multicolored from '../../img/logo/2x/kisfaludy_logo_multicolored-2x.png';
import fbIcon from '../../img/icons/fb-icon.svg'
//IMGS END

//FILES
import Pdf_adatvedelmi from '../../files/adatvedelmi_tajekoztato.pdf';
import Pdf_aszf from '../../files/aszf.pdf';


class Footer extends Component {


    render() {
        const { t } = this.props;
        return (

            <footer id="footer" className="bg--dark texture-dark text-center-xs text-left space--xs">
                <div className="footer__bg-container">
                    <span className="footer__waves wave--left"></span>
                    <span className="footer__waves wave--right"></span>
                </div>
                <div className="container container-xl">

                    <div className="row align-items-start justify-content-center justify-content-md-center">

                        <div className="col-12 col-md-3 pb-5 pb-md-0 align-self-stretch">

                            <div className="footer__logo__wrapper">

                                <img src={kisfaludy_logo_multicolored} className="logo" alt="Kisfaludy Logó" />


                            </div>

                        </div>

                        <div className="col-12 text-center text-md-left col-md-4 col-xl-3">
                            <ul className="list footer__nav hover-wrapper">
                                <h6 className="footer-col-titles">{t("Footer_section.t12")}</h6>
                                <hr className="hr-st-margin"></hr>



                                <li className="menu-items-hover"><Link className="menu-items-hover" to="/kisfaludybar">{t("Footer_section.t0")}</Link></li>

                                <li className="menu-items-hover"><Link smooth to="/#menetrendjegyarak" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}>{t("Footer_section.t2")}</Link></li>


                                <li className="menu-items-hover"><Link className="menu-items-hover" smooth to="/#terkep" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}>{t("Footer_section.t3")}</Link></li>


                                <li className="menu-items-hover"><Link className="menu-items-hover" smooth to="/#gyik" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t("Footer_section.t4")}</Link></li>

                                <li className="menu-items-hover"> <a href="#start">{t("Footer_section.t1")}</a></li>
                            </ul>
                        </div>

                        <div className="col-12 text-center text-md-left col-md-4 col-xl-3">

                            <ul className="list footer__nav hover-wrapper">
                                <h6 className="footer-col-titles">{t("Footer_section.t14")}</h6>
                                <hr className="hr-st-margin"></hr>

                                <li><Link className="menu-items-hover" smooth to="/ceges-rendezvenyek" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}>{t("Footer_section.t11")}</Link></li>


                                <li><Link className="menu-items-hover" smooth to="/csaladi-rendezvenyek" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}>{t("Footer_section.t10")}</Link></li>


                                <li><Link className="menu-items-hover" smooth to="/foto-es-forgatas" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t("Footer_section.t15")}</Link></li>


                                <li><Link className="menu-items-hover" smooth to="/eskuvo-rendezvenyek" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t("Footer_section.t16")}</Link></li>

                            </ul>
                        </div>

                        <div className="col-12 text-center text-md-left col-md-4 col-xl-3">
                            <ul className="list footer__nav last-col">
                                <h6 className="footer-col-titles">{t("Footer_section.t13")}</h6>
                                <hr className="hr-st-margin"></hr>
                                <div className="row">
                                    <div className="col-12 py-4 py-md-0">
                                        <ul className="list-inline list-contact">

                                            <a href="https://www.facebook.com/kisfaludygozos" target="_blan" rel="noreferrer">
                                                <li className="fb-icon-wrapper"><img className="fb-icon" src={fbIcon} alt="facebook-icon" />Facebook</li>
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                            </ul>
                            <div className="row credits-area">
                                <div className="col-12 left-padding">
                                    <span className="type--fine-print credits-area__wrapper">
                                        <span className="block">{t("Footer_section.t5")}<a className="hover-darker" target="_blank" rel="noreferrer" href="http://isf.hu"
                                        >{t("Footer_section.t6")}</a></span>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 left-padding">
                                    <span className="type--fine-print">
                                        <span><a target="_blank" rel="noreferrer" className="type--fine-print pdf-hover-footer" href={Pdf_adatvedelmi}
                                        >{t("Footer_section.t8")}</a></span>
                                        <span><a target="_blank" rel="noreferrer" className="type--fine-print pdf-hover-footer" href={Pdf_aszf}
                                        >{t("Footer_section.t9")}</a></span>
                                    </span><br />
                                    <span className="all-right-reserved">{t("Footer_section.t7")}</span>
                                </div>
                            </div>





                        </div>


                    </div>



                </div>




            </footer>

        )
    }

}

export default withTranslation()(Footer);