//COMPONENTS
import React, { Component} from 'react';
import {  Suspense } from 'react';
import { withTranslation } from 'react-i18next';
//COMPONENTS END

//MYCOMPONENTS
import Aux from '../../src/components/Hook/Auxiliary'
import EmailSender from '../components/Form/EventPageSenderForm/emailSender';
//MYCOMPONENTS END

//IMGS

//1.PHOTOS
import kisfaludy_480_03 from '../img/480/kisfaludy-480-03.jpg';
import kisfaludy_512_03 from '../img/512/kisfaludy-512-03.jpg';
import kisfaludy_768_03 from '../img/768/kisfaludy-768-03.jpg';
import kisfaludy_992_03 from '../img/992/kisfaludy-992-03.jpg';
import kisfaludy_1024_03 from '../img/1024/kisfaludy-1024-03.jpg';
import kisfaludy_1280_03 from '../img/1280/kisfaludy-1280-03.jpg';
import kisfaludy_1680_03 from '../img/1680/kisfaludy-1680-03.jpg';
import kisfaludy_1920_03 from '../img/1920/kisfaludy-1920-03.jpg';

import kisfaludy_480_02 from '../img/480/kisfaludy-480-02.jpg';
import kisfaludy_512_02 from '../img/512/kisfaludy-512-02.jpg';
import kisfaludy_768_02 from '../img/768/kisfaludy-768-02.jpg';
import kisfaludy_992_02 from '../img/992/kisfaludy-992-02.jpg';
import kisfaludy_1024_02 from '../img/1024/kisfaludy-1024-02.jpg';
import kisfaludy_1280_02 from '../img/1280/kisfaludy-1280-02.jpg';
import kisfaludy_1680_02 from '../img/1680/kisfaludy-1680-02.jpg';
import kisfaludy_1920_02 from '../img/1920/kisfaludy-1920-02.jpg';

//2.LOGOS
import Stripe175 from '../components/Stripe175/index';
import logo_175_dark from '../img/graphic_assets/logo-175-dark.png';

//IMGS END


class Tortenelem extends Component {
   
    render() {
        const { t } = this.props;
        return (
       <Aux>

                <section className="hero-section hero-section--tortenelem" id="tortenelem-hero">

                    <span className="filter--black filter--black--gradient"></span>
                    <div className="hero-section__content">
                        <div className="container container-xl">
                            <div className="col-12 col-md-8 col-lg-6">
                                <div className="hero-section__text">
                                    <h1 className="hero-section__title"><strong>{t("TortenelemPage.Hero_section.boxTitle")}</strong></h1>
                                    <hr className="short" />
                                    <div className="hero-section__bodytext">
                                        <p>{t("TortenelemPage.Hero_section.bodyText")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>
              
                    <Stripe175 />
         
                <section className="section story--section py-5 py-lg-6">
                    <div className="container container-xl">

                        <div className="section-content__wrapper row align-items-stretch justify-content-center">
                            <div className="section-content__text col-12 col-lg-6 pr-lg-5">

                                <h1 className="section-content__title">{t("TortenelemPage.Story_section.boxTitle")}</h1>
                                <hr className="short" />
                                <div className="section-content__bodytext">
                                    <p>
                                        {t("TortenelemPage.Story_section.bodyText.p1")}
                                    </p>

                                    <p>
                                        {t("TortenelemPage.Story_section.bodyText.p2")}
                                    </p>

                                    <p>
                                        {t("TortenelemPage.Story_section.bodyText.p3")}
                                    </p>
                                </div>


                            </div>
                            <div className="section-content__pic story col-12 col-lg-6">
                                <img src={kisfaludy_480_03}
                                    srcSet={`${kisfaludy_512_03} 512w, ${kisfaludy_768_03} 768w,${kisfaludy_992_03} 992w,${kisfaludy_1024_03} 1024w, ${kisfaludy_1280_03} 1280w, ${kisfaludy_1680_03} 1680w,${kisfaludy_1920_03} 1920w`}
                                    alt="A Kisfaludy gőzhajó Balatonfüred előtt" />
                            </div>
                        </div>

                    </div>

                </section>

                <section className="section story--section py-5 py-lg-6">
                    <div className="container container-xl">

                        <div className="section-content__wrapper row align-items-stretch justify-content-center">

                            <div className="section-content__text col-12 col-lg-6 pl-lg-5 order-1 order-lg-2">
                                <h1 className="section-content__title">{t("TortenelemPage.Story_section2.boxTitle")}</h1>
                                <hr className="short" />
                                <div className="section-content__bodytext">
                                    <p>
                                        {t("TortenelemPage.Story_section2.bodyText.p1")}
                                    </p>

                                    <p>
                                        {t("TortenelemPage.Story_section2.bodyText.p2")}
                                    </p>

                                    <p>
                                        {t("TortenelemPage.Story_section2.bodyText.p3")}
                                    </p>

                                    <p>
                                        {t("TortenelemPage.Story_section2.bodyText.p4")}
                                    </p>
                                </div>
                            </div>

                            <div className="section-content__pic rebuild col-12 col-lg-6 order-1">

                                <img src={kisfaludy_480_02}
                                    srcSet={`${kisfaludy_512_02} 512w, ${kisfaludy_768_02} 768w,${kisfaludy_992_02} 992w,${kisfaludy_1024_02} 1024w, ${kisfaludy_1280_02} 1280w, ${kisfaludy_1680_02} 1680w,${kisfaludy_1920_02} 1920w`}
                                    alt=" A hajó felavatása pezsgővel" />
                            </div>

                        </div>

                    </div>

                </section>

                <section id="anniversary-175" className="section curiosities-section bg--secondary py-5 py-lg-6">
                    <div className="container container-xl">

                        {/* Anniversary section */}
                        <div className="anniversary-box section-content__wrapper row justify-content-center">
                            <div className="col-12 col-md-10 nopadding boxed boxed--shadow boxed--border">

                                <div className="row justify-content-center">
                                    <div className="section__img-container col-12 align-self-center">
                                        <img src={logo_175_dark} alt='tortenelem-hajo' />
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="section__text-block col-12">
                                        
                                            <h1 className="text-block__title--big">
                                                {t("TortenelemPage.Anniversary_175_section.title")}
                                            </h1>
                                            <hr className="short" />
                                            
                                                <div className="text-block__body-text text--lg-2col">
                                                    <p>
                                                        {t("TortenelemPage.Anniversary_175_section.bodyText.p1")}
                                                    </p>

                                                    <span className="p-with-quote">
                                                        <p>
                                                            {t("TortenelemPage.Anniversary_175_section.bodyText.p2")}
                                                        </p>

                                                        <p className="p--quote">{t("TortenelemPage.Anniversary_175_section.bodyText.quote1")}</p> 
                                                    </span>


                                                    <span className="p-with-quote">
                                                        <p>
                                                            {t("TortenelemPage.Anniversary_175_section.bodyText.p3")}
                                                        </p>

                                                        <blockquote>
                                                            {t("TortenelemPage.Anniversary_175_section.bodyText.quote2")}
                                                        </blockquote>
                                                    </span>



                                                </div>
                                         


                                        
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className="section-content__wrapper row justify-content-center">
                            <div className="col-12 col-md-10 nopadding">
                                <div className="boxed boxed--shadow boxed--border">
                                    <h1 className="section-content__title">{t("TortenelemPage.Story_section3.boxTitle")}</h1>
                                    <hr className="short" />
                                    <ul className="list list-style-light list-loose">
                                        <li>{t("TortenelemPage.Story_section3.bodyText.p1")}
                                        </li>
                                        <li>{t("TortenelemPage.Story_section3.bodyText.p2")}</li>
                                        <li>{t("TortenelemPage.Story_section3.bodyText.p3")}</li>
                                        <li>{t("TortenelemPage.Story_section3.bodyText.p4")}</li>
                                        <li>{t("TortenelemPage.Story_section3.bodyText.p5")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section contact-us--section bg--secondary">

<div className="container container-xl">
    <div className="row justify-content-between ">


        <div className="col-12 col-lg-6">
            <div className="text-container section__text-block">

                <h3 className="text-block__title">                                                           
                {t("EventsPage.Events_section.bodyText.form_section.boxTitle")}
                </h3>
                <hr className="short " />
                <p>                                                            
                    {t("EventsPage.Events_section.bodyText.form_section.introText")}
                </p>
                <ul>
                    <li><b>{t("Map_section.subtitle")}  </b>{t("Map_section.subtitleText")}</li>

                </ul>


            </div>
        </div>

        <div className="col-12 col-lg-6">

            <div className="form-container boxed boxed--compact boxed--border border--light boxed--shadow">
            <Suspense fallback={<h1>Loading</h1>}>
                    <EmailSender />
                    </Suspense>
            </div>

        </div>

    </div>


</div>


</section>
                </Aux>
        )
    }
}

export default withTranslation()(Tortenelem);