//COMPONENTS START

import ModalImage from "react-modal-image";
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import React, { Component, Suspense } from 'react';
import Sticky from 'react-sticky-el';

//COMPONENTS END


//MYCOMPONENTS START

import EmailSender from '../../components/Form/EventsPagesSenderForm/emailSender';
import Gallery from '../../../src/components/Gallery/index';
import Aux from '../../components/Hook/Auxiliary/index'

//MYCOMPONENTS END


//IMGS START

import kisfaludy_768_43 from '../../img/768/kisfaludy-ceges-02.jpg';
import kisfaludy_1680_43 from '../../img/1680/kisfaludy-ceges-02.jpg';

import kisfaludy_768_27 from '../../img/768/kisfaludy-768-27.jpg';
import kisfaludy_1680_27 from '../../img/1680/kisfaludy-1680-27.jpg';

import kisfaludy_768_ceges from '../../img/768/kisfaludy-ceges-03.jpg';
import kisfaludy_1680_ceges from '../../img/1680/kisfaludy-ceges-03.jpg';

import cSecond from '../../img/ceges_2.jpg';

//IMGS END

class Corp extends Component {

    state = {
        isSticky: ''
    }
    componentDidMount() {
        if (window.innerWidth > 991) {
            this.setState({
                isSticky: true
            });
        }

        window.addEventListener('resize', () => {
            this.setState({
                isSticky: window.innerWidth > 991
            });
        }, false);
    }

    render() {
        const { t } = this.props;
        const stickyAndForm = this.state.isSticky ?
            (
                <div className="test single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                    <Sticky className="single-event__email-block" boundaryElement=".container" hideOnBoundaryHit={false} topOffset={0}>
                    <Suspense fallback={<h1>Loading</h1>}>
                        <EmailSender />
                        </Suspense>
                    </Sticky>
                </div>
            ) :
            (<div className="single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                <Suspense fallback={<h1>Loading</h1>}>
                    <EmailSender />
                    </Suspense>
            </div>);

        return (
            <Aux>
                <section className="section hero-section hero-section--ceges">
                    <span className="filter--black filter--black--gradient"></span>
                    <div className="hero-section__content">
                        <div className="container container-xl">
                            <div className="col-12 col-md-8 col-lg-6">
                                <div className="hero-section__text">
                                    <h1 className="hero-section__title">
                                        <strong>
                                            {t("EventSinglePages.Page_Corporate.Hero_section.title")}
                                        </strong>
                                    </h1>
                                    <hr className="short" />
                                    <div class="hero-section__bodytext">
                                        <p> {t("EventSinglePages.Page_Corporate.Hero_section.introText.p1")} </p>
                                        <p> {t("EventSinglePages.Page_Corporate.Hero_section.introText.p2")} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg--secondary section single-event--section">

                    <div className="container container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    {stickyAndForm}
                                    <div className="col-12 col-lg-6 mt-3 mt-md-0">

                                        <div className="section__main-content">

                                            <div className="section__image-block">

                                                {/*  <img src={kisfaludy_480_27}
                                                    srcSet={`${kisfaludy_512_27} 512w, ${kisfaludy_768_27} 768w`}
        alt="Kisfaludy sétahajó a Tihanyi Apátság közelében" />*/}
                                                <ModalImage


                                                    small={kisfaludy_768_27}
                                                    large={kisfaludy_1680_27}
                                                    alt="Kisfaludy sétahajó a Tihanyi Apátság közelében"
                                                />

                                            </div>


                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_01.title")}
                                                </h6>
                                                <p>  {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_01.p1")} </p>
                                                <p>  {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_01.p2")}</p>
                                            </div>



                                            <div className="section__image-block">
                                                {/*<img src={kisfaludy_480_43}
                                                    srcSet={`${kisfaludy_512_43} 512w, ${kisfaludy_768_43} 768w`}
    alt="Céges megbeszélés a Kisfaludy fedélzetén" />*/}
                                                <ModalImage


                                                    small={kisfaludy_768_43}
                                                    large={kisfaludy_1680_43}
                                                    alt="Kisfaludy sétahajó a Tihanyi Apátság közelében"
                                                />
                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_02.title")}
                                                </h6>

                                                <p> {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_02.p1")} </p>

                                            </div>

                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={cSecond}
                                                    large={cSecond}
                                                    alt="Céges bor- sajt- és sörkóstolók a Kisfaludy fedélzetén"
                                                />
                                                {/*<img src={cSecond}
                                                    alt="Céges bor- sajt- és sörkóstolók a Kisfaludy fedélzetén" />*/}

                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_03.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Corporate.BodyContent.textBlock_03.p1")}
                                                </p>

                                                <div className="section__image-block">

                                                    {/*<img src={kisfaludy_480_ceges}
                                                        srcSet={`${kisfaludy_512_ceges} 512w, ${kisfaludy_768_ceges} 768w`}
                                                alt="Kisfaludy sétahajó a Tihanyi Apátság közelében" />-*/}
                                                    <ModalImage


                                                        small={kisfaludy_768_ceges}
                                                        large={kisfaludy_1680_ceges}
                                                        alt="Kisfaludy sétahajó a Tihanyi Apátság közelében"
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                        <div className="autowidth backBtn">
                                            <Link
                                                to='/rendezvenyek'
                                            >

                                                <span className="btn btn--simple btn--back type--uppercase autowidth">
                                                    {t("EventSinglePages.Buttons.backToEvents")}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="gallery-section">
                        <Sticky mod={'bottom'} boundaryElement=".gallery-section" hideOnBoundaryHit={true}>
                            <Gallery name="Corp" />
                        </Sticky>
                    </div>
                </section>

            </Aux>)
    }
}

export default withTranslation()(Corp);