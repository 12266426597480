import React from 'react';
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';


import Aux from '../../components/Hook/Auxiliary'





class NotFoundPage extends React.Component {



    render() {
        const { t } = this.props;

        return (
            <Aux>
                <section className="page-not-found--section">
                    <div className="page-not-found__container">
                        <div className="container">
                            <div className="row justify-content-center align-items-stretch">
                                <div className="col-12 col-lg-6">
                                    <div className="page-not-found__illustration"></div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h1 className="page-not-found__errorcode">{t('Page404.title')}</h1>
                                    <div className="page-not-found__text">
                                        <p>{t('Page404.text')}.</p>
                                    </div>
                                    <div className="page-not-found__btn">
                                        <Link
                                            to='/'
                                        ><span>
                                                <i className="btn btn-circle btn-back"></i>{t('Page404.buttonText')}</span>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }

}
export default withTranslation()(NotFoundPage);