import React from 'react';
import { useHistory } from 'react-router-dom';


//import LanguageSelector from '../LanguageSelectorUI/languageSelectorUI';

import Header from '../../components/Partials/Header'

//const Header = lazy(() => import('../../components/Partials/Header'))



const Checker = () => {


    const history = useHistory()
    let sendingData = false;
    let active404 = false;
    if (history.location.pathname === '/') {
        sendingData = true;
    } 
    else if(history.location.pathname !== '/rendezvenyek' && history.location.pathname !== '/eskuvo-rendezvenyek' && history.location.pathname !== '/csaladi-rendezvenyek' && history.location.pathname !== '/ceges-rendezvenyek' && history.location.pathname !== '/foto-es-forgatas' && history.location.pathname !== '/tortenelem' && history.location.pathname !== '/kisfaludybar') {
     active404 = true;
    }

    else {

    }

    return (

        <Header pathName={sendingData} page404={active404}/>


    )
}

export default Checker;