//COMPONENTS START

import ModalImage from "react-modal-image";
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import React, { Component, Suspense } from 'react';
import Sticky from 'react-sticky-el';

//COMPONENTS END


//MYCOMPONENTS START
import Aux from '../../components/Hook/Auxiliary';
import Gallery from '../../../src/components/Gallery/index';
import EmailSender from '../../../src/components/Form/EventsPagesSenderForm/emailSender';
//MYCOMPONENTS END


//IMGS START

import kisfaludy_512_49 from '../../img/512/kisfaludy-csaladi-02.jpg';
import kisfaludy_1680_49 from '../../img/1680/kisfaludy-csaladi-02.jpg';

import kisfaludy_512_csaladi from '../../img/512/kisfaludy-csaladi-01.jpg';
import kisfaludy_1680_csaladi from '../../img/1680/kisfaludy-csaladi-01.jpg';

import kisfaludy_512_47 from '../../img/512/kisfaludy-csaladi-07.jpg';
import kisfaludy_1680_47 from '../../img/1680/kisfaludy-csaladi-07.jpg';

import kisfaludy_512_50 from '../../img/512/kisfaludy-csaladi-11.jpg';
import kisfaludy_1680_50 from '../../img/1680/kisfaludy-csaladi-11.jpg';

//IMGS END


class Family extends Component {


    state = {
        isSticky: ''
    }

    componentDidMount() {
        if (window.innerWidth > 991) {
            this.setState({
                isSticky: true
            });
        }

        window.addEventListener('resize', () => {
            this.setState({
                isSticky: window.innerWidth > 991
            });
        }, false);
    }

    render() {
        const { t } = this.props;
        const stickyAndForm = this.state.isSticky ?
            (
                <div className="test single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                    <Sticky className="single-event__email-block" boundaryElement=".container" hideOnBoundaryHit={false} topOffset={0}>
                    <Suspense fallback={<h1>Loading</h1>}>
                        <EmailSender />
                        </Suspense>
                    </Sticky>
                </div>
            ) :
            (<div className="single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                    <Suspense fallback={<h1>Loading</h1>}>
                    <EmailSender />
                    </Suspense>
            </div>);

        return (
            <Aux>
                <section className="section hero-section hero-section--csaladi">

                    <span className="filter--black filter--black--gradient"></span>
                    <div className="hero-section__content">
                        <div className="container container-xl">
                            <div className="col-12 col-md-8 col-lg-6">
                                <div className="hero-section__text">
                                    <h1 className="hero-section__title">
                                        <strong>
                                            {t("EventSinglePages.Page_Family.Hero_section.title")}
                                        </strong>
                                    </h1>
                                    <hr className="short" />
                                    <div className="hero-section__bodytext">
                                        <p>
                                            {t("EventSinglePages.Page_Family.Hero_section.introText.p1")}
                                        </p>
                                        <p>
                                            {t("EventSinglePages.Page_Family.Hero_section.introText.p2")}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="bg--secondary section single-event--section">

                    <div className="container container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">


                                    {stickyAndForm}



                                    <div className="col-12 col-lg-6 pb-3 pb-md-0 order-1">

                                        <div className="section__main-content">

                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={kisfaludy_512_49}
                                                    large={kisfaludy_1680_49}
                                                    alt="Kisfaludy sétahajó a Tihanyi Apátság közelében"
                                                />

                                            </div>


                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_01.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_01.p1")}
                                                </p>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_01.p2")}
                                                </p>

                                            </div>



                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={kisfaludy_512_csaladi}
                                                    large={kisfaludy_1680_csaladi}
                                                    alt="Születésnapok reformkori környezetben"
                                                />
                                            

                                            </div>

                                            <div className="section__text-block">

                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_02.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_02.p1")}
                                                </p>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_02.p2")}
                                                </p>

                                            </div>

                                            <div className="section__image-block">
                                          
                                                <ModalImage


                                                    small={kisfaludy_512_47}
                                                    large={kisfaludy_1680_47}
                                                    alt="Ünnepi asztaldíszítés a hajón"
                                                />
                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_03.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_03.p1")}
                                                </p>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_03.p2")}
                                                </p>

                                            </div>

                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={kisfaludy_512_50}
                                                    large={kisfaludy_1680_50}
                                                    alt="Ünnepi asztaldíszítés a hajón"
                                                />
                                       
                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_04.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Family.BodyContent.textBlock_04.p1")}
                                                </p>

                                            </div>
                                        </div>

                                        <div className="autowidth backBtn">
                                            <Link
                                                to='/rendezvenyek'
                                            >


                                                <span className="btn btn--simple btn--back type--uppercase autowidth">
                                                    {t("EventSinglePages.Buttons.backToEvents")}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="gallery-section">
                        <Gallery name="Family" />
                    </div>
                </section>
            </Aux>)
    }
}

export default withTranslation()(Family);