import React from 'react';
import Sticky from 'react-sticky-el';
import { useSelector } from 'react-redux';
import i18 from 'i18next';

import CheckerHook from '../../components/Hook/index'
import Footer from '../../components/Partials/newFooter'
import { useHistory } from 'react-router-dom';


const mapState = state => ({
    language: state.languageData.currentLanguage
});



const Layout = (props) => {

    const { language } = useSelector(mapState);

    if (i18) {


        if (i18.language !== language) {


            i18.changeLanguage(language);

        }
    }


    const history = useHistory()
    let kisFaludyBarClass = "";

    if (history.location.pathname === '/kisfaludybar') {
        kisFaludyBarClass = " kisfaludybar-page"
    }
    else {
        kisFaludyBarClass = "";
    }





    return (
        <div className={'main-container'+kisFaludyBarClass}>
       
            {history.location.pathname === '/kisfaludybar' ?  
            (   
                <div></div>
            ) : 
            (   <Sticky className="nav--section" topOffset={150}>
                <CheckerHook />
            </Sticky>
            )}
      
            
            {props.children}
            
            <Footer />
  
        </div>
    )
}
export default Layout;