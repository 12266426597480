//COMPONENTS START

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import React, { Suspense, Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

//COMPONENTS END

//MYCOMPONENTS START

import Preloader from '../src/components/Preloader/Preloader';
import SchrollToTop from '../src/components/SchrollToTOp/index'
import Layout from '../src/components/Layout/index';
import MyDocumentPc from '../src/components/PdfViewer/index'
//MYCOMPONENTS END

//PAGES START

import HomePage from './pages/HomePage.jsx';
import Tortenelem from './pages/Tortenelem';
import Rendezvenyek from './pages/Rendezvenyek';
import CegesRendezvenyek from './pages/CorporateEvent/index.js';
import CsRendezvenyek from './pages/FamilyEvent/index.js';
import FotoEsForgatas from './pages/PhotoEvent/index.js';
import Eskuvo from './pages/Wedding/index.js';
import NotFoundPage from './pages/404/index';
import KisfaludyBar from './pages/KisfaludyBar/kisfaludybar.js';

//PAGES END

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import TagManager from 'react-gtm-module'
import { PDFViewer } from '@react-pdf/renderer';
const tagManagerArgs = {
  gtmId: 'GTM-MLZKZ7K'
}
TagManager.initialize(tagManagerArgs)


class App extends Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      isLoad: false
    }
  }


  authenticate() {
    return new Promise(resolve => setTimeout(resolve, 3000))
  }


  componentDidMount() {

    window.addEventListener('load', this.handleLoad)
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      const body = document.getElementById('body')

      if (ele) {
        // fade out
        ele.classList.add('available')
        setTimeout(() => {

          // remove from DOM
          ele.outerHTML = ''
          body.classList.add('bodyCss')
          this.setState({ isLoad: true });
        }, 3000)
        body.classList.remove('overflow-rule-before');
        body.classList.add('scrollable-content');
      }
    })
    //this.getGeoInfo();




  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }

  handleLoad() {
    this.setState({ isLoad: true });

  }

  render() {
 
    return (
      <Suspense fallback={
        <Preloader />}>

        <MuiPickersUtilsProvider utils={MomentUtils}>

          <BrowserRouter>

            <Switch>

              <Route exact path="/">
                <Layout>
                  <SchrollToTop />
                  <HomePage />
                </Layout>
              </Route>

              <Route path="/rendezvenyek">
                <Layout>
                  <SchrollToTop />
                  <Rendezvenyek />
                </Layout>
              </Route>

              <Route path="/eskuvo-rendezvenyek">
                <Layout>
                  <SchrollToTop />
                  <Eskuvo />
                </Layout>
              </Route>

              <Route path="/csaladi-rendezvenyek">
                <Layout>
                  <SchrollToTop />
                  <CsRendezvenyek />
                </Layout>
              </Route>


              <Route path="/ceges-rendezvenyek">
                <Layout>
                  <SchrollToTop />
                  <CegesRendezvenyek />
                </Layout>
              </Route>

              <Route path="/foto-es-forgatas">
                <Layout>
                  <SchrollToTop />
                  <FotoEsForgatas />
                </Layout>
              </Route>

        
              <Route path="/tortenelem">
                <Layout>
                  <SchrollToTop />
                  <Tortenelem />
                </Layout>
              </Route>

              <Route path="/kisfaludybar">
                <Layout>
                  <SchrollToTop />
                  <KisfaludyBar/>
                </Layout>
              </Route>


              <Route path="">
                <Layout>
                  <NotFoundPage />
                </Layout>
              </Route>
          
            </Switch>
          </BrowserRouter>

        </MuiPickersUtilsProvider>
  
      </Suspense>
    
 
    );
  }
}

export default App;
