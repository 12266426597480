import languageTypes from './language.types';

const INITIAL_STATE = {
  currentLanguage: 'hu',
};


const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case languageTypes.LANGUAGE_CHANGE_SUCCESS:
      
      return {
        ...state,
        currentLanguage: action.payload,
      }
    default:
      return state;
  }
};

export default languageReducer;