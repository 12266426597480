//COMPONENTS START

import Sticky from 'react-sticky-el';
import ModalImage from "react-modal-image";
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import React, { Component, Suspense } from 'react';

//COMPONENTS END


//MYCOMPONENTS START

import Aux from '../../components/Hook/Auxiliary';
import Gallery from '../../../src/components/Gallery/index';
import EmailSender from '../../../src/components/Form/EventsPagesSenderForm/emailSender';

//MYCOMPONENTS END


//IMGS START

import kisfaludy_512_38 from '../../img/512/kisfaludy-512-38.jpg';
import kisfaludy_1680_38 from '../../img/1680/kisfaludy-1680-38.jpg';

import kisfaludy_512_37 from '../../img/512/kisfaludy-512-37.jpg';
import kisfaludy_1680_37 from '../../img/1680/kisfaludy-1680-37.jpg';

import kisfaludy_512_36 from '../../img/512/kisfaludy-512-36.jpg';
import kisfaludy_1680_36 from '../../img/1680/kisfaludy-1680-36.jpg';

//IMGS END

class Photo extends Component {


    state = {
        isSticky: ''
    }

    componentDidMount() {
        if (window.innerWidth > 991) {
            this.setState({
                isSticky: true
            });
        }

        window.addEventListener('resize', () => {
            this.setState({
                isSticky: window.innerWidth > 991
            });
        }, false);
    }
    render() {
        const { t } = this.props;
        const stickyAndForm = this.state.isSticky ?
            (
                <div className="test single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                    <Sticky className="single-event__email-block" boundaryElement=".container" hideOnBoundaryHit={false} topOffset={0}>
                        <Suspense fallback={<h1>Loading</h1>}>
                            <EmailSender />
                        </Suspense>
                    </Sticky>
                </div>
            ) :
            (<div className="single-event__email-block col-12 col-lg-6 mt-3 mt-md-0 order-2">
                <Suspense fallback={<h1>Loading</h1>}>
                    <EmailSender />
                </Suspense>
            </div>);
        return (
            <Aux>
                <section class="section hero-section hero-section--foto">

                    <span class="filter--black filter--black--gradient"></span>
                    <div class="hero-section__content">
                        <div class="container container-xl">
                            <div class="col-12 col-md-8 col-lg-6">
                                <div class="hero-section__text">
                                    <h1 class="hero-section__title">
                                        <strong>
                                            {t("EventSinglePages.Page_Photo.Hero_section.title")}
                                        </strong>
                                    </h1>
                                    <hr class="short" />
                                    <div class="hero-section__bodytext">
                                        <p>{t("EventSinglePages.Page_Photo.Hero_section.introText.p1")}</p>
                                        <p>{t("EventSinglePages.Page_Photo.Hero_section.introText.p2")}</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                <section className="bg--secondary section single-event--section">

                    <div className="container container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-12">
                                <div className="row justify-content-between align-items-center align-items-lg-stretch">
                                    {stickyAndForm}

                                    <div className="col-12 col-lg-6 pb-3 pb-md-0 order-1">

                                        <div className="section__main-content">

                                            <div className="section__image-block">

                                                <ModalImage


                                                    small={kisfaludy_512_36}
                                                    large={kisfaludy_1680_36}
                                                    alt="Forgatás a patinás fedélzeten"
                                                />
                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_01.title")}
                                                </h6>

                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_01.p1")}
                                                </p>

                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_01.p2")}
                                                </p>

                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_01.p3")}
                                                </p>
                                            </div>



                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={kisfaludy_512_37}
                                                    large={kisfaludy_1680_37}
                                                    alt="Forgatás a patinás fedélzeten"
                                                />

                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_02.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_02.p1")}
                                                </p>

                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_02.p2")}
                                                </p>
                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_02.p3")}
                                                </p>
                                            </div>

                                            <div className="section__image-block">
                                                <ModalImage


                                                    small={kisfaludy_512_38}
                                                    large={kisfaludy_1680_38}
                                                    alt="Egyedi fotósorozatok helyszíne a Kisfaludy lapátkerekes gőzhajó"
                                                />

                                            </div>

                                            <div className="section__text-block">
                                                <h6 className="text-block__title">
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_03.title")}
                                                </h6>
                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_03.p1")}
                                                </p>
                                                <p>
                                                    {t("EventSinglePages.Page_Photo.BodyContent.textBlock_03.p2")}
                                                </p>
                                            </div>

                                        </div>
                                        <div className="autowidth backBtn">
                                            <Link
                                                to='/rendezvenyek'
                                            >


                                                <span className="btn btn--simple btn--back type--uppercase autowidth">
                                                    {t("EventSinglePages.Buttons.backToEvents")}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="gallery-section">
                        <Gallery name="Photo" />
                    </div>
                </section>
            </Aux>)
    }
}


export default withTranslation()(Photo);