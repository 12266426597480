import React, { Component, lazy, Suspense } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import kisfaludy_logo_compact_colored from '../../img/logo/1x/kisfaludy_logo_compact_colored.png';

import kisfaludy_logo_compact_colored_animated from '../../img/graphic_assets/animated-logo/kf.svg';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';



const LanguageSelector = lazy(() => import('../LanguageSelectorUI/languageSelectorUI'))
class Header extends Component {
    constructor() {
        super();

        this.toggleNav = this.toggleNav.bind(this);
    }


    toggleNav() {
        var hamburgerMenu = $(".hamburger-toggle");
        var navBar = $(".nav-container");
        var navBarMenu = $("#menu1");
        var stickyClass = "sticky";
        var menuActive = "active";
     

        $(hamburgerMenu).click(function () {

            if (!$(navBarMenu).hasClass(menuActive)) {
                $(navBarMenu).addClass(menuActive);
            } else {
                $(navBarMenu).removeClass(menuActive);
            }

            if (!$(navBar).hasClass(stickyClass)) {
                $(navBar).addClass(stickyClass);
            }
           
        });





    };
    /* let nav = [];
     const history = useHistory()
     const { t } = useTranslation();
 
     if(history.location.pathname === '/'){
         nav=<Link smooth to="/#bemutatkozas">{t('Navbar.button1')}</Link>                             
     }else{
         nav=<Link smooth to="/">{t('Navbar.homepage')}</Link>
     }*/
    componentDidMount() {
        this.toggleNav()
    }
    render() {

        const { t } = this.props
        let nav = [];
        let style404 = {};
        //const history = useHistory()*/

        /* if (this.props.pathName) {
             nav = <Link smooth to="/#bemutatkozas" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t('Navbar.button1')} </Link>
         } else {
             nav = <Link smooth to="/">{t('Navbar.homepage')}</Link>
         }*/



        if (this.props.pathName) {
            nav =
                <ul className="menu menu--left menu--horizontal">
                    <li>
                        <Link smooth to="/#menetrendjegyarak" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t('Navbar.button1')} </Link>
                    </li>

                    <li><Link to="/kisfaludybar">{t('Navbar.button7')}</Link></li>

                    <li>
                        <Link smooth to="/#terkep" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t('Navbar.button2')}</Link>
                    </li>
                </ul>

        } else {
            nav =
                <ul className="menu menu--left menu--horizontal">
                    <li>
                        <Link smooth to="/">{t('Navbar.homepage')}</Link>
                    </li>


                    <li>
                        <Link smooth to="/#menetrendjegyarak" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t('Navbar.button1')} </Link>
                    </li>
                    <li><Link to="/kisfaludybar">{t('Navbar.button7')}</Link></li>

                    </ul>
        }
        // nav = <Link smooth to="/#menetrendjegyarak" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center' })}>{t('Navbar.button1')} </Link>


        const { hash } = window.location;



        if (this.props.page404 && hash !== '#index') {

            style404 = {
                background: '#252525',
                'border-bottom': '4px solid #eecf3b'
            }
        }

        return (

            <section className="section nav--section">

                <div className="nav-container" style={style404}>

                    <div className="bar bar--sm visible-xs visible-sm">

                        <div className="container container-xl">
                            <div className="row justify-content-between">
                                <div className="col-4">

                                    <div className="logo logo--mobile">
                                        <a className="logo logo--mobile mobile-logo" href="/">
                                            <img src={kisfaludy_logo_compact_colored}
                                                alt="Logo of Kisfaludy_promo" />
                                        </a>
                                    </div>

                                </div>

                                <div className="col-3 text-right">
                                    <span href="#" className="hamburger-toggle" data-toggle-classname="#menu1;hidden-sm hidden-xs">
                                        <i className="icon icon--sm stack-interface stack-menu"></i>
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className="container container-xl">

                        <nav id="menu1" className="menu-container bar bar--sm bar-1 hidden-sm ">

                            {nav}
                            <div className="logo-container">

                                <div className="logo logo--center">


                                    <object className="animated-logo-navbar" type="image/svg+xml" data={kisfaludy_logo_compact_colored_animated}>svg</object>


                                </div>

                            </div>



                            <ul className="menu menu--horizontal menu--right">
                                <li>
                                    <Link
                                        to='/rendezvenyek'
                                    >
                                        {t('Navbar.button3')}
                                    </Link>


                                </li>
                                <li>
                                    <Link to='/tortenelem'>
                                        {t('Navbar.button4')}
                                    </Link>
                                </li>



                                <li>
                                    <Suspense fallback={(<div>Loading</div>)}><LanguageSelector /></Suspense></li>
                            </ul>

                        </nav>

                    </div>
                </div>
            </section>



        )
    }
}

export default withTranslation()(Header);