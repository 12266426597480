import React, { Component } from 'react';
import logo_animated from '../../img/graphic_assets/animated-logo/kisfaludy-animated-logo-origi.svg';

class Preloader extends Component {

    constructor() {
        super();
        this.state = { isLoading: false }
    }


    componentDidMount() {
        this.setState({ isLoading: true })
        //window.scrollTo(0, 0)
    }
    render() {
        return (
            

            <section id="preloader" className="">
                <div className="preloader__content-wrapper">
                   
                    <div className="preloader__logo">
                        <img alt="animated-logo" src={logo_animated} />
                    </div>
                    <div className="preloader__text">
                        {/* <h4>Az oldal betöltés alatt</h4> */}
                    </div>

                </div>
            </section>


        )
    }
}

export default Preloader;