import appearTypes from './appear.types';

const INITIAL_STATE = {
  currentState: false,
};


const appearReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case appearTypes.APPEAR_CHANGE_SUCCESS:
      
      return {
        ...state,
        currentState: action.payload,
      }
    default:
      return state;
  }
};

export default appearReducer;